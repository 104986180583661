import { FormControl, InputLabel, Select, MenuItem, type SelectChangeEvent } from '@mui/material';
import { useTranslate } from 'react-admin';

import { type IMonthOption } from '@/view/dashboard/Dashboard/components/ChartMonthSelect/utils';

interface IChartMonthSelectProps {
  handleMonthChange: (event: SelectChangeEvent) => void;
  monthOptions: IMonthOption[];
  selectedMonth: string;
}

export function ChartMonthSelect({
  handleMonthChange,
  monthOptions,
  selectedMonth,
}: IChartMonthSelectProps): JSX.Element | null {
  const t = useTranslate();

  return (
    <>
      {selectedMonth ? (
        <FormControl fullWidth>
          <InputLabel id="dashboard-month-chart-select-label">
            {t('resources.dashboard.choose_month')}
          </InputLabel>
          <Select
            value={selectedMonth}
            labelId="dashboard-month-chart-select-label"
            id="dashboard-month-chart-select"
            onChange={handleMonthChange}
          >
            {monthOptions.map(({ name, id }) => {
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null}
    </>
  );
}

export default ChartMonthSelect;
