import { type SvgIconTypeMap } from '@mui/material';
import { type OverridableComponent } from '@mui/types';
import { type To } from 'react-router-dom';

import { CardWithIcon } from '@/view/dashboard/Dashboard/components/CardWithIcon';

interface ICardValueProps {
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  redirect: To;
  title: string;
  value: string;
}

function CardValue({ icon, redirect, title, value }: ICardValueProps): JSX.Element {
  return <CardWithIcon to={redirect} icon={icon} title={title} subtitle={value} />;
}

export default CardValue;
