import { API_HANDLERS } from '@/api';
import { IDashboardRequestParams, IDashboardResponseParams } from '@/types';

interface IGetDashboardData {
  requestData: IDashboardRequestParams;
  onError: (message: string) => void;
}

export const getDashboardData = async ({
  requestData,
  onError,
}: IGetDashboardData): Promise<IDashboardResponseParams> => {
  try {
    const { data: responseData } = await API_HANDLERS.DASHBOARD.GET_DASHBOARD_DATA(requestData);

    return Promise.resolve(responseData);
  } catch (error) {
    // @ts-expect-error type for error
    onError(error?.response?.data?.message as string);
    return Promise.reject(new Error(`Get Dashboard Data Error: ${error}`));
  }
};
