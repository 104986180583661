import { API_PATHS } from '@/api';
import { postData } from '@/api/crudHandlers';
import {
  TAxiosResponseSuccess,
  ITransactionsRequestParams,
  ITransactionsResponseParams,
  ISignTransactionWithdrawalOrderRequestParams,
} from '@/types';

export const transactionsHandlers = {
  GET_LIST(data: ITransactionsRequestParams): TAxiosResponseSuccess<ITransactionsResponseParams> {
    const startDate =
      data?.filter?.startDate && new Date(`${data.filter.startDate} 00:00:00.000`).toISOString();
    const endDate =
      data?.filter?.endDate && new Date(`${data.filter.endDate} 23:59:59.999`).toISOString();

    return postData<ITransactionsResponseParams>(API_PATHS.TRANSACTIONS.LIST._, {
      data: {
        filter: {
          status: data?.filter?.status,
          startDate,
          endDate,
          companyId: data?.filter?.companyId,
          withdrawalOrderStatus: data?.filter?.withdrawalOrderStatus,
          itn: data?.filter?.itn,
        },
      },
      params: {
        search: data?.search,
        order: data?.order,
        page: data?.page,
        take: data?.take,
      },
    });
  },
  EXPORT(data: ITransactionsRequestParams): TAxiosResponseSuccess<{ Location: string }> {
    return postData<{ Location: string }>(API_PATHS.TRANSACTIONS.EXPORT._, {
      data: {
        filter: {
          status: data?.filter?.status,
          startDate: data?.filter?.startDate,
          endDate: data?.filter?.endDate,
          companyId: data?.filter?.companyId,
          withdrawalOrderStatus: data?.filter?.withdrawalOrderStatus,
          itn: data?.filter?.itn,
        },
      },
      params: {
        search: data?.search,
        order: data?.order,
        page: data?.page,
        take: data?.take,
      },
    });
  },
  SIGN_WITHDRAWAL_ORDER(
    data: ISignTransactionWithdrawalOrderRequestParams,
  ): TAxiosResponseSuccess<never> {
    const form = new FormData();
    form.append('transactionId', data.transactionId);
    form.append('file', data.file, 'signed.p7s');

    return postData<never>(API_PATHS.TRANSACTIONS.SIGN_WITHDRAWAL_ORDER._, {
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
