import { GetListParams, GetListResult } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { ITransactionsRequestParams, ITransactionsResponseData } from '@/types';

export const getList = async (
  data?: GetListParams,
): Promise<GetListResult<ITransactionsResponseData>> => {
  try {
    const requestData: ITransactionsRequestParams = {
      filter: {
        status: data?.filter.status,
        startDate: data?.filter.startDate,
        endDate: data?.filter.endDate,
        companyId: data?.filter.companies,
        withdrawalOrderStatus: data?.filter.withdrawalOrderStatus,
        itn: data?.filter?.itn,
      },
      search: data?.filter.full_name,
      page: data?.pagination.page,
      // order: data?.sort.order,
      order: 'DESC',
      take: data?.pagination.perPage,
    };

    const { data: responseData } = await API_HANDLERS.TRANSACTIONS.GET_LIST(requestData);

    return Promise.resolve({
      data: responseData.data,
      total: responseData.meta.itemCount,
      pageInfo: {
        hasNextPage: responseData.meta.hasNextPage,
        hasPreviousPage: responseData.meta.hasPreviousPage,
      },
    });
  } catch (error) {
    // @ts-expect-error message in response
    if (error?.response?.data?.message === 'Start date cannot be before 1 January 2024.') {
      return Promise.reject(new Error('Початкова дата не може бути раніше 1 січня 2024 року'));
      // @ts-expect-error message in response
    } else if (error?.response?.data?.message === 'End date cannot be in the future.') {
      return Promise.reject(new Error('Кінцева дата не може бути раніше за сьогодні'));
    }
    return Promise.reject(new Error(`Get Transactions List Error: ${error}`));
  }
};
