import { List } from 'react-admin';

import { CompaniesEmpty } from '@/view/companies/CompaniesEmpty';
import {
  companiesFilters,
  ListActions,
  TabbedDatagrid,
} from '@/view/companies/CompaniesList/components';

function CompaniesList(): JSX.Element {
  return (
    <List
      sort={{ field: 'company_name', order: 'DESC' }}
      perPage={25}
      filters={companiesFilters}
      actions={<ListActions />}
      empty={<CompaniesEmpty />}
    >
      <TabbedDatagrid />
    </List>
  );
}

export default CompaniesList;
