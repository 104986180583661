import { AxiosHeaderValue } from 'axios';

export function normalizeHeaders(headers: {
  [key: string]: AxiosHeaderValue | undefined;
}): Record<string, string | undefined> {
  const normalizedHeaders: Record<string, string | undefined> = {};
  Object.keys(headers).forEach(key => {
    const value = headers[key];
    normalizedHeaders[key] = value !== undefined && value !== null ? String(value) : undefined;
  });
  return normalizedHeaders;
}
