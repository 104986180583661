import { ChangePasswordForm } from '@/view/settings/ChangePassword/components/form/ChangePasswordForm';

function ChangePassword(): JSX.Element {
  return (
    <>
      <ChangePasswordForm />
    </>
  );
}

export default ChangePassword;
