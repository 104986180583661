interface IQueryParams {
  [key: string]: string;
}

export function parseQuery(query: string): IQueryParams {
  if (query.startsWith('?')) {
    query = query.substring(1);
  }

  const params: IQueryParams = {};
  const pairs = query.split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1] || '');

    params[key] = value;
  }

  return params;
}
