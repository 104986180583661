import {
  Divider,
  // Theme,
  // useMediaQuery,
} from '@mui/material';
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  NumberField,
  TextField,
} from 'react-admin';

import { DownloadPDF } from '@/view/transactions/TransactionsList/components/DownloadPDF';
import { WithdrawalOrderPDF } from '@/view/transactions/TransactionsList/components/WithdrawalOrderPDF';

function TabbedDatagrid(): JSX.Element {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <>
      <Divider />
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <TextField
          source="employee.first_name"
          label="resources.transactions.first_name"
          sortable={false}
        />
        <TextField
          source="employee.last_name"
          label="resources.transactions.last_name"
          sortable={false}
        />
        <TextField source="employee.itn" label="resources.transactions.itn" sortable={false} />
        <TextField source="status" label="resources.transactions.status" sortable={false} />
        <TextField
          source="company.company_name"
          label="resources.transactions.companies"
          sortable={false}
        />
        <DateField
          source="createdAt"
          label="resources.transactions.createdAt"
          sortable={false}
          showTime
        />
        <NumberField
          sortable={false}
          source="amount"
          label="resources.transactions.amount"
          options={{ style: 'currency', currency: 'UAH' }}
        />
        <FunctionField render={DownloadPDF} label="resources.transactions.download_pdf" />
        <FunctionField
          render={WithdrawalOrderPDF}
          label="resources.transactions.withdrawal_order_pdf"
        />
      </DatagridConfigurable>
    </>
  );
}

export default TabbedDatagrid;
