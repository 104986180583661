import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button } from '@mui/material';
import { useTranslate } from 'react-admin';

interface IImportCSVButtonProps {
  handleOpenImportCSVModal: () => void;
}

function ImportCSVButton({ handleOpenImportCSVModal }: IImportCSVButtonProps): JSX.Element {
  const t = useTranslate();

  return (
    <Button
      type="button"
      variant="text"
      sx={{ padding: '4px 5px', lineHeight: 1.5 }}
      startIcon={<AddCircleOutlineIcon fontSize="small" />}
      onClick={handleOpenImportCSVModal}
    >
      {t('resources.employees.import_csv')}
    </Button>
  );
}

export default ImportCSVButton;
