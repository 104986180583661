import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useNotify, useTranslate } from 'react-admin';

import { employeesDataProvider, filtersDataProvider } from '@/dataProvider';
import { ICompaniesFilter } from '@/types';

interface IImportCSVModalProps {
  handleClose: () => void;
  isOpen: boolean;
  setIsPollingActive: Dispatch<SetStateAction<boolean>>;
  setSqsMessageId: Dispatch<SetStateAction<string>>;
}

function ImportCSVModal({
  handleClose,
  isOpen,
  setIsPollingActive,
  setSqsMessageId,
}: IImportCSVModalProps): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [companiesFilter, setCompaniesFilter] = useState<ICompaniesFilter[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('');

  const handleCompanyChange = (event: SelectChangeEvent): void => {
    setSelectedCompany(event.target.value as string);
  };

  const uploadXLS = async (file: FormData): Promise<void> => {
    const messageId = await employeesDataProvider.uploadEmployees({
      file,
      companyId: selectedCompany,
    });
    notify('resources.employees.import_csv_status.in_progress', {
      type: 'warning',
      autoHideDuration: 9500,
    });
    setSqsMessageId(messageId);
    setIsPollingActive(true);
    handleClose();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    uploadXLS(formData);
  };

  const handleClick = (): void => {
    fileInputRef.current?.click();
  };

  const getCompaniesFilter = async (): Promise<ICompaniesFilter[]> => {
    const companies = await filtersDataProvider.getCompanies();
    setCompaniesFilter(companies);

    return companies;
  };

  useEffect(() => {
    void getCompaniesFilter();
  }, []);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '400px',
          height: '270px',
          maxWidth: '100%',
          maxHeight: '100%',
        },
      }}
      open={isOpen}
      onClose={handleClose}
      id="import-csv-file"
    >
      <DialogTitle
        sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        id="import-csv-file-title"
      >
        {t('resources.employees.import_csv_modal_title')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: theme => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="company-select-label">
            {t('resources.employees.choose_company')}
          </InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={selectedCompany}
            onChange={handleCompanyChange}
          >
            {companiesFilter.map(({ id, company_name }) => (
              <MenuItem key={id} value={id}>
                {company_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button type="button" variant="contained" disabled={!selectedCompany} onClick={handleClick}>
          {t('resources.employees.choose_csv')}
        </Button>
      </DialogContent>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept=".xls, .xlsx"
      />
    </Dialog>
  );
}

export default ImportCSVModal;
