import { API_PATHS } from '@/api';
import { postData, putData, getData } from '@/api/crudHandlers';
import {
  TAxiosResponseSuccess,
  IEmployeesRequestParams,
  IEmployeesResponseParams,
  IEmployeesTerminateRequestParams,
  IEmployeesUploadXLSRequestParams,
  IEmployeesUploadXLSResponseParams,
  IEmployeesCheckUploadXLSResponseParams,
} from '@/types';

export const employeesHandlers = {
  GET_LIST(data?: IEmployeesRequestParams): TAxiosResponseSuccess<IEmployeesResponseParams> {
    return postData<IEmployeesResponseParams>(API_PATHS.EMPLOYEES.LIST._, {
      data: {
        filter: {
          status: data?.filter?.status,
          companyId: data?.filter?.companyId,
        },
      },
      params: {
        search: data?.search,
        order: data?.order,
        page: data?.page,
        take: data?.take,
      },
    });
  },
  UPLOAD_EMPLOYEES(
    data: IEmployeesUploadXLSRequestParams,
  ): TAxiosResponseSuccess<IEmployeesUploadXLSResponseParams> {
    const { file, companyId } = data;

    return postData<IEmployeesUploadXLSResponseParams>(
      API_PATHS.EMPLOYEES.UPLOAD_EMPLOYEES.BY_ID(companyId),
      {
        data: file,
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },
  UPLOAD_EMPLOYEES_STATUS(
    messageId: string,
  ): TAxiosResponseSuccess<IEmployeesCheckUploadXLSResponseParams> {
    return getData<IEmployeesCheckUploadXLSResponseParams>(
      API_PATHS.EMPLOYEES.UPLOAD_EMPLOYEES_STATUS._,
      {
        params: {
          messageId: messageId,
        },
      },
    );
  },
  TERMINATE(data: IEmployeesTerminateRequestParams): TAxiosResponseSuccess<void> {
    return putData<void>(API_PATHS.EMPLOYEES.TERMINATE._, {
      data,
    });
  },
};
