import { API_PATHS } from '@/api';
import { postData } from '@/api/crudHandlers';
import { TAxiosResponseSuccess, IDashboardRequestParams, IDashboardResponseParams } from '@/types';

export const dashboardHandlers = {
  GET_DASHBOARD_DATA: ({
    filter,
  }: IDashboardRequestParams): TAxiosResponseSuccess<IDashboardResponseParams> => {
    return postData<IDashboardResponseParams>(API_PATHS.DASHBOARD.DASHBOARD_DATA._, {
      data: {
        filter: {
          ...filter,
          companyId: filter?.companyId ? filter?.companyId : null,
        },
      },
    });
  },
};
