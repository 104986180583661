import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { type TooltipProps } from 'recharts';

function CustomChartTooltip({ payload }: TooltipProps<string, string>): JSX.Element | null {
  const t = useTranslate();

  if (payload?.length) {
    const amount = payload[0].payload.amount;
    const date = payload[0].payload.normalDate;
    const operations = payload[0].payload.operations;

    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          padding: '10px',
          backgroundColor: '#ffffff',
          borderRadius: '2px',
          border: '1px solid rgba(0,0,0,0.12)',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
      >
        <Typography variant="subtitle1">{date}</Typography>
        <Typography variant="subtitle1">
          {t('resources.dashboard.amount')}: {amount} ₴
        </Typography>
        <Typography variant="subtitle1">
          {t('resources.dashboard.transactions')}: {operations}
        </Typography>
      </Box>
    );
  }

  return null;
}

export default CustomChartTooltip;
