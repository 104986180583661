import axios from 'axios';

import ApiServicesMediator from '@/api/apiServicesMediator';
import AuthService from '@/api/authService';
import { TAxiosResponseSuccess, IClientConfig } from '@/types';
import { normalizeHeaders } from '@/utils';

const apiEndpoint = import.meta.env.VITE_REACT_APP_PUBLIC_BACKEND_HTTP_API_ENDPOINT;

function createHttpClient() {
  ApiServicesMediator.add(AuthService);

  return axios.create({
    baseURL: apiEndpoint,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export const httpClient = createHttpClient();

httpClient.interceptors.request.use(function (config) {
  // We need to cancel all the requests if session is already expired, because after expiration,
  // token is cleared and next responses would be just plain unauthorized, as if user was not logged in
  // This would cause auth service to trigger logout event, which is not desired behavior.
  if (AuthService.getStatus() === 'sessionExpired') {
    return {
      ...config,
      // eslint-disable-next-line import/no-named-as-default-member
      cancelToken: new axios.CancelToken(cancel => cancel('Session expired')),
    };
  }
  return config;
});

export async function makeHttpRequest<SuccessPayload>(
  config: IClientConfig,
  token?: string,
): TAxiosResponseSuccess<SuccessPayload> {
  const { headers, authorizeRequest = true } = config;

  if (authorizeRequest) {
    const freshToken = await AuthService.refreshToken();
    if (token) {
      const headersToSend = headers ? normalizeHeaders(headers) : {};
      headersToSend['Authorization'] = `Token ${token}`;
      config.headers = headersToSend;
    } else {
      const headersToSend = headers ? normalizeHeaders(headers) : {};
      headersToSend['Authorization'] = freshToken ? `Bearer ${freshToken}` : undefined;
      config.headers = headersToSend;
    }
  }

  return httpClient.request<SuccessPayload>(config);
}
