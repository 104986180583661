import { Mutex } from 'async-mutex';
import axios, { AxiosError, AxiosResponse, AxiosStatic } from 'axios';
import jwt_decode from 'jwt-decode';

import { API_HANDLERS } from '@/api/apiHandlers';
import { API_PATHS } from '@/api/apiPaths';
// import ApiServicesMediator, { MediatedService, Mediator } from '@/api/apiServicesMediator';
import { MediatedService } from '@/api/apiServicesMediator';
import { AuthDataEvent, UserLogoutEvent, UserSessionExpiredEvent } from '@/api/authEvents';
import { AuthStatus, AuthStatuses } from '@/api/authStatuses';
import { TOKEN_REFRESH_THRESHOLD } from '@/api/constants';
import Subject from '@/api/observer';
import UserPermissionsService from '@/api/userPermissionsService';
import { FLEXIPAY_USER_AVATAR } from '@/constants';
import {
  IJwtPayload,
  IRefreshTokenResponseSuccess,
  ISignInRequestParams,
  ISignInResponseFailure,
  ISignInResponseSuccess,
  IUserInfo,
  RegistrationTypeEnum,
} from '@/types';

const apiEndpoint = import.meta.env.VITE_REACT_APP_PUBLIC_BACKEND_HTTP_API_ENDPOINT;
export const localStorageAccessKey = '__auth_provider_access__';
export const localStorageRefreshKey = '__auth_provider_refresh__';

type THttpClient = AxiosStatic;

type TSessionCleanupStatus =
  | typeof AuthStatuses.Unauthorized
  | typeof AuthStatuses.SessionExpired
  | typeof AuthStatuses.Pending;

class AuthService implements MediatedService<AuthStatus> {
  private status: AuthStatus = AuthStatuses.Pending;
  private userInfo: IUserInfo | null = null;
  private refreshMutex: Mutex = new Mutex();

  private setStatus = (newStatus: AuthStatus) => {
    this.status = newStatus;
    this.subject.dispatch(this.status);
  };

  private onInit = async () => {
    const token = await this.getAccessToken();

    this.userPermissionsService.subscribe(() => {
      const newStatus = this.userPermissionsService.calculateUserStatus();
      if (newStatus !== this.status) {
        this.setStatus(newStatus);
      }
    });

    if (!token) {
      this.setStatus(AuthStatuses.Unauthorized);
    } else {
      // Initialize user data for actual info
      try {
        // TODO: Need to get profile data
        // await API_HANDLERS.PROFILE.GET();
      } catch (e) {
        this.setStatus(AuthStatuses.Unauthorized);
      }
    }
  };

  constructor(
    private subject: Subject<AuthStatus>,
    private storage: Storage,
    private httpClient: THttpClient,
    private userPermissionsService: typeof UserPermissionsService,
    private authEventSubject: Subject<AuthDataEvent>,
    // private mediator: Mediator<AuthStatus>,
  ) {
    this.subscribe = this.subscribe.bind(this);
    this.subscribeToAuthEvents = this.subscribeToAuthEvents.bind(this);

    this.onInit();
  }

  private getAccessTokenSynchronously = () => {
    return this.storage.getItem(localStorageAccessKey);
  };

  private getRefreshTokenSynchronously = () => {
    return this.storage.getItem(localStorageRefreshKey);
  };

  private clearAccessToken = (status: TSessionCleanupStatus): void => {
    this.storage.removeItem(localStorageAccessKey);
    this.storage.removeItem(localStorageRefreshKey);
    this.setStatus(status);
  };

  private clearUserInfo = (): void => {
    this.userInfo = null;
  };

  private expireSession = (): void => {
    const currentStatus = this.getStatus();
    this.clearAccessToken(AuthStatuses.SessionExpired);
    this.authEventSubject.dispatch(new UserSessionExpiredEvent(currentStatus));
  };

  private setAccessToken = (token: string): void => {
    this.storage.setItem(localStorageAccessKey, token);
  };

  private setRefreshToken = (token: string): void => {
    this.storage.setItem(localStorageRefreshKey, token);
  };

  private shouldTokenRefresh = (token: string) => {
    const { exp } = jwt_decode<IJwtPayload>(token);
    const timeLeft = exp * 1000 - new Date().getTime(); //ms

    return timeLeft < TOKEN_REFRESH_THRESHOLD;
  };

  private getUserRoleFromJwt = (token: string): RegistrationTypeEnum => {
    const { role } = jwt_decode<IJwtPayload>(token);

    return role;
  };

  private setUserInfo = (newUserInfo: IUserInfo) => {
    this.userInfo = newUserInfo;
  };

  private saveUserInfoFromJwt = (token: string) => {
    const { firstName, lastName, permissions, role, userId } = jwt_decode<IJwtPayload>(token);

    this.setUserInfo({ firstName, lastName, permissions, role, userId });
    this.userPermissionsService.updateUserPermissions({
      firstName,
      lastName,
      permissions,
      role,
      userId,
    });
  };

  receive = (message: AuthStatus) => {
    switch (message) {
      case AuthStatuses.SessionExpired:
        this.expireSession();
        break;
      case AuthStatuses.Unauthorized:
        void this.signOut();
        break;
    }
  };

  getStatus = () => this.status;

  getAsyncUserInfo = async (): Promise<IUserInfo | null> => {
    const userInfo = await this.userInfo;

    return Promise.resolve(userInfo);
  };

  getUserInfo = (): IUserInfo | null => {
    const userInfo = this.userInfo;

    return userInfo;
  };

  getUserRole = async (): Promise<RegistrationTypeEnum | null> => {
    const token = await this.getAccessToken();

    if (token) {
      const role = this.getUserRoleFromJwt(token);

      return role;
    } else {
      return null;
    }
  };

  getUserCompanyId = async (): Promise<string | null> => {
    const role = await this.getUserRole();
    const token = await this.getAccessToken();

    if (role === RegistrationTypeEnum.COMPANY_ADMIN && token) {
      const { companyId } = jwt_decode<IJwtPayload>(token);

      return companyId as string;
    } else {
      return null;
    }
  };

  getAccessToken = async (): Promise<string | null> => {
    return this.getAccessTokenSynchronously();
  };

  getRefreshToken = async (): Promise<string | null> => {
    return this.getRefreshTokenSynchronously();
  };

  subscribe = (observerFn: (data: AuthStatus) => void) => {
    return this.subject.subscribe(observerFn);
  };

  subscribeToAuthEvents = (observerFn: (data: AuthDataEvent) => void) => {
    return this.authEventSubject.subscribe(observerFn);
  };

  clearSession = (): void => {
    this.clearAccessToken(AuthStatuses.Unauthorized);
  };

  redirectToLogin = (): void => {
    window.location.assign('/#/login');
  };

  refreshToken = async (baseUrl?: string): Promise<string | null> => {
    const currentToken = await this.getAccessToken();

    if (!currentToken) {
      return null;
    }

    let shouldTokenRefresh;
    try {
      shouldTokenRefresh = this.shouldTokenRefresh(currentToken); // token could be "12345" which cause error in jwt-decode lib
    } catch {
      shouldTokenRefresh = true;
    }

    if (!shouldTokenRefresh) {
      return currentToken;
    }

    if (this.refreshMutex.isLocked()) {
      await this.refreshMutex.waitForUnlock();

      const newToken = await this.getAccessToken();

      return newToken;
    }

    const mutexRelease = await this.refreshMutex.acquire();

    const refreshTokenValue = await this.getRefreshToken();

    let token = null;
    try {
      const response = await this.httpClient.post<IRefreshTokenResponseSuccess>(
        API_PATHS.AUTH.REFRESH_TOKEN._,
        {
          refreshToken: refreshTokenValue,
        },
        {
          baseURL: baseUrl || apiEndpoint,
        },
      );

      token = response.data.accessToken;

      this.setAccessToken(token);
      this.setRefreshToken(response.data.refreshToken);

      return token;
    } catch (error) {
      this.clearSession();
      this.redirectToLogin();

      return token;
    } finally {
      mutexRelease();
    }
  };

  // eslint-disable-next-line
  checkError = (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      this.clearSession();
      return Promise.reject();
    }
    return Promise.resolve();
  };

  checkAuth = async (): Promise<void> => {
    try {
      const token = await this.getAccessToken();
      if (!token) {
        this.redirectToLogin();
        return Promise.reject(new Error('Не авторизовано'));
      }

      const refreshedToken = await this.refreshToken();
      if (!refreshedToken) {
        throw new Error('Token refresh failed');
      }

      this.saveUserInfoFromJwt(refreshedToken);
      return Promise.resolve();
    } catch (error) {
      console.error('Error during authentication check:', error);
      return Promise.reject(error);
    }
  };

  getIdentity = async () => {
    const token = await this.getAccessToken();

    if (token) {
      const user = jwt_decode<IJwtPayload>(token);

      return Promise.resolve({
        id: user.userId,
        fullName: `${user.firstName} ${user.lastName}`,
        avatar: FLEXIPAY_USER_AVATAR,
      });
    }

    return Promise.resolve({
      id: 'user',
      fullName: 'User',
      avatar: FLEXIPAY_USER_AVATAR,
    });
  };

  signIn = async (data: ISignInRequestParams): Promise<AxiosResponse<ISignInResponseSuccess>> => {
    try {
      if (this.status !== AuthStatuses.Unauthorized) {
        this.clearAccessToken(AuthStatuses.Pending);
      }
      const response = await API_HANDLERS.AUTH.SIGN_IN(data);

      const userRole = this.getUserRoleFromJwt(response.data.accessToken);
      const newStatus =
        userRole === RegistrationTypeEnum.SUPER_ADMIN
          ? AuthStatuses.SuperAdminAuthorized
          : AuthStatuses.CompanyAdminAuthorized;

      if (newStatus !== this.status) {
        this.setStatus(newStatus);
      }

      this.setAccessToken(response.data.accessToken);
      this.setRefreshToken(response.data.refreshToken);
      this.saveUserInfoFromJwt(response.data.accessToken);

      return response;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError<ISignInResponseFailure>;
        if (serverError && serverError.response) {
          const errorMessage = serverError.response.data.message;

          if (Array.isArray(errorMessage)) {
            throw new Error(errorMessage.join('; '));
          } else {
            throw new Error(errorMessage);
          }
        }
      }
      throw new Error('An unknown error occurred');
    }
  };

  signOut = async (): Promise<void> => {
    await API_HANDLERS.AUTH.SIGN_OUT();
    const currentAuthStatus = this.getStatus();
    this.clearAccessToken(AuthStatuses.Unauthorized);
    this.clearUserInfo();
    this.authEventSubject.dispatch(new UserLogoutEvent(currentAuthStatus));
    return Promise.resolve();
  };
}

export default new AuthService(
  new Subject<AuthStatus>(),
  window.localStorage,
  axios,
  UserPermissionsService,
  new Subject<AuthDataEvent>(),
  // ApiServicesMediator,
);
