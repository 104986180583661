import { GetListParams } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { ITransactionsRequestParams } from '@/types';

export const exportTransactions = async (data?: GetListParams): Promise<string> => {
  try {
    const requestData: ITransactionsRequestParams = {
      filter: {
        status: data?.filter.status,
        startDate: data?.filter.startDate,
        endDate: data?.filter.endDate,
        companyId: data?.filter.companies,
        withdrawalOrderStatus: data?.filter.withdrawalOrderStatus,
        itn: data?.filter?.itn,
      },
      search: data?.filter.full_name,
      page: data?.pagination.page,
      order: data?.sort.order,
      take: data?.pagination.perPage,
    };

    const { data: responseData } = await API_HANDLERS.TRANSACTIONS.EXPORT(requestData);

    return Promise.resolve(responseData.Location);
  } catch (error) {
    return Promise.reject(new Error(`Get Transactions XLS Error: ${error}`));
  }
};
