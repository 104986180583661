import { zodResolver } from '@hookform/resolvers/zod';
import { Avatar, Button, Card, CardActions, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslate, useNotify } from 'react-admin';
import { useForm, Controller } from 'react-hook-form';

import loginBackgroundImage from '@/assets/img/loginBackgroundImage.webp';
import FlexipayIcon from '@/assets/svg/flexipay-logo-icon.svg';
import { authDataProvider } from '@/dataProvider/authDataProvider';
import { getForgotPasswordValidationSchema } from '@/view/layout/ForgotPassword/validation';

interface IFormData {
  email: string;
}

function ForgotPassword(): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();

  const zodSchema = getForgotPasswordValidationSchema(t);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormData>({
    mode: 'onChange',
    resolver: zodResolver(zodSchema),
  });

  const onSuccess = (): void => {
    notify(t('forgot_password.errors.valid_email'), {
      type: 'success',
    });
  };

  // eslint-disable-next-line
  const onError = (error: any): void => {
    if (error.response.data.message) {
      notify(t(error.response.data.message), {
        type: 'error',
      });
    } else {
      notify(t('forgot_password.errors.invalid_email'), {
        type: 'error',
      });
    }
  };

  const checkEmail = async (email: string): Promise<boolean> => {
    const response = await authDataProvider.forgotPassword({ email, onSuccess, onError });

    return response;
  };

  const onSubmit = ({ email }: IFormData): void => {
    void checkEmail(email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `url(${loginBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.light' }}>
              <img src={FlexipayIcon} alt="FlexipayIcon" width={40} height={40} />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          >
            <strong>FlexiPay</strong>
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em', justifyContent: 'center' }}>
            <Button type="submit" variant="contained" color="primary" disabled={!isValid}>
              {t('forgot_password.confirm_email_btn')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}

export default ForgotPassword;
