import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { type Identifier, useGetRecordId } from 'react-admin';

import { Loader } from '@/components/Loader';
import { companiesDataProvider } from '@/dataProvider';
import { ICompanyItemResponseParams } from '@/types';
import { EditCompanyForm } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm';

function CompaniesEdit(): JSX.Element {
  const recordId = useGetRecordId();

  const [companyData, setCompanyData] = useState<ICompanyItemResponseParams>();

  const getCompanyItemById = async (companyId: Identifier): Promise<ICompanyItemResponseParams> => {
    const { data } = await companiesDataProvider.getItem({ companyId });
    setCompanyData(data);

    return data;
  };

  useEffect(() => {
    if (recordId) {
      void getCompanyItemById(recordId);
    }
  }, [recordId]);

  return (
    <>
      {!companyData ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '90vh', width: '73vw' }}
        >
          <Loader />
        </Box>
      ) : (
        <Box>
          <EditCompanyForm formDefaultValues={companyData} companyId={recordId} />
        </Box>
      )}
    </>
  );
}

export default CompaniesEdit;
