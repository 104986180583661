import { createCompany } from '@/dataProvider/companiesDataProvider/createCompany';
import { createCredentials } from '@/dataProvider/companiesDataProvider/createCredentials';
import { editCompany } from '@/dataProvider/companiesDataProvider/editCompany';
import { editCredentials } from '@/dataProvider/companiesDataProvider/editCredentials';
import { getCredentialsList } from '@/dataProvider/companiesDataProvider/getCredentialsList';
import { getItem } from '@/dataProvider/companiesDataProvider/getItem';
import { getCompany, getList } from '@/dataProvider/companiesDataProvider/getList';
import { uploadContract } from '@/dataProvider/companiesDataProvider/uploadContract';
import { uploadLogo } from '@/dataProvider/companiesDataProvider/uploadLogo';

export const companiesDataProvider = {
  createCompany,
  createCredentials,
  editCompany,
  editCredentials,
  getCredentialsList,
  getList,
  uploadLogo,
  getItem,
  getCompany,
  uploadContract,
};
