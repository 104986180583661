import { ReferenceInput, SearchInput } from 'react-admin';

import { EmployeesCompaniesAutocompleteInput } from '@/view/employees/EmployeesList/components/EmployeesCompaniesAutocompleteInput';
import { EmployeesStatusAutocompleteInput } from '@/view/employees/EmployeesList/components/EmployeesStatusAutocompleteInput';

export const adminEmployeesFilters: JSX.Element[] = [
  <SearchInput source="full_name" alwaysOn />,
  <ReferenceInput label="resources.employees.status" source="status" reference="employees_status">
    <EmployeesStatusAutocompleteInput />
  </ReferenceInput>,
];

export const superAdminEmployeesFilters: JSX.Element[] = [
  <SearchInput source="full_name" alwaysOn />,
  <ReferenceInput label="resources.employees.status" source="status" reference="employees_status">
    <EmployeesStatusAutocompleteInput />
  </ReferenceInput>,
  <ReferenceInput
    label="resources.employees.companies"
    source="companies"
    reference="employees_companies"
  >
    <EmployeesCompaniesAutocompleteInput />
  </ReferenceInput>,
];
