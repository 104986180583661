import { AuthStatuses } from '@/api/authStatuses';
import { USER_GROUPS, USER_PERMISSIONS } from '@/api/constants';
import Subject, { IObserver, IObserverFunction } from '@/api/observer';
import { TPermissionsType, RegistrationTypeEnum, IUserInfo, IRbac } from '@/types';
import { transformPermissionsData } from '@/utils';

export const userPermissionsEvents = {
  permissionsUpdate: 'permissionsUpdate',
} as const;

const basicPermissionGroups = [USER_GROUPS.AUTHENTICATION];

const coreSuperAdminPermissionGroups = [USER_GROUPS.AUTHENTICATION];
const coreCompanyPermissionGroups = [USER_GROUPS.AUTHENTICATION, ...basicPermissionGroups];

type UserPermissions = keyof typeof USER_PERMISSIONS;
const corePermissionGroups: Record<string, UserPermissions[]> = {
  [RegistrationTypeEnum.COMPANY_ADMIN]: coreCompanyPermissionGroups,
  [RegistrationTypeEnum.SUPER_ADMIN]: coreSuperAdminPermissionGroups,
};

// This service must not be used outside the API client
class UserPermissionsService {
  constructor(private subject: Subject<keyof typeof userPermissionsEvents>) {
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
    this.updateUserPermissions = this.updateUserPermissions.bind(this);
    this.calculateUserStatus = this.calculateUserStatus.bind(this);
  }

  private _permissions: TPermissionsType[] | null = null;
  private user: IUserInfo | null = null;

  private calculateCompanyAdminStatus = (user: IUserInfo) => {
    switch (true) {
      case this.hasUserCorePermissions(user.role):
        return AuthStatuses.CompanyAdminAuthorized;
      case this.hasUserBasicPermissions():
        return AuthStatuses.CompanyAdminRegistering;
      case !this.hasUserCorePermissions(user.role):
      case !this.hasUserBasicPermissions():
        return AuthStatuses.Locked;
      default:
        return AuthStatuses.Unauthorized;
    }
  };

  private calculateSuperAdminStatus = (user: IUserInfo) => {
    switch (true) {
      case this.hasUserBasicPermissions() && this.hasUserCorePermissions(user.role):
        return AuthStatuses.SuperAdminAuthorized;
      case this.hasUserCorePermissions(user.role):
      case this.hasUserBasicPermissions():
        return AuthStatuses.SuperAdminRegistering;
      case !this.hasUserCorePermissions(user.role):
      case !this.hasUserBasicPermissions():
        return AuthStatuses.Locked;
      default:
        return AuthStatuses.Unauthorized;
    }
  };

  calculateUserStatus = () => {
    switch (this.user?.role) {
      case RegistrationTypeEnum.SUPER_ADMIN:
        return this.calculateSuperAdminStatus(this.user);
      case RegistrationTypeEnum.COMPANY_ADMIN:
        return this.calculateCompanyAdminStatus(this.user);
      default:
        return AuthStatuses.Unauthorized;
    }
  };

  updateUserPermissions(user: IUserInfo) {
    this.user = user;
    this._permissions = [user.permissions];

    this.subject.dispatch(userPermissionsEvents.permissionsUpdate);
  }

  // subscribe = this.subject.subscribe.bind(this.subject);

  subscribe(observerFn: IObserverFunction<keyof typeof userPermissionsEvents>) {
    return this.subject.subscribe(observerFn);
  }

  unsubscribe(observer: IObserver<keyof typeof userPermissionsEvents>) {
    this.subject.unsubscribe(observer);
  }

  get permissions() {
    return this._permissions;
  }

  get rbacData(): IRbac[] {
    return this._permissions ? transformPermissionsData(this._permissions) : [];
  }

  hasUserCorePermissions(registrationType: RegistrationTypeEnum) {
    const permissions = this._permissions;

    if (!permissions) {
      return false;
    }

    return corePermissionGroups[registrationType].every(corePermissionGroup => {
      const groupPermissions = USER_PERMISSIONS[corePermissionGroup] as readonly TPermissionsType[];

      return groupPermissions.every((groupPermission: TPermissionsType) =>
        permissions.includes(groupPermission),
      );
    });
  }

  hasUserBasicPermissions() {
    const permissions = this._permissions;

    if (!permissions) {
      return false;
    }

    return basicPermissionGroups.every(corePermissionGroup => {
      const groupPermissions = USER_PERMISSIONS[corePermissionGroup] as readonly TPermissionsType[];

      return groupPermissions.every((groupPermission: TPermissionsType) =>
        permissions.includes(groupPermission),
      );
    });
  }
}

export default new UserPermissionsService(new Subject<keyof typeof userPermissionsEvents>());
