import {
  // FilterButton,
  SelectColumnsButton,
  TopToolbar,
} from 'react-admin';

import { useGetUserRole } from '@/hooks';
import { RegistrationTypeEnum } from '@/types';
import { AddCompanyButton } from '@/view/companies/CompaniesList/components';

function ListActions(): JSX.Element {
  const role = useGetUserRole();

  return (
    <TopToolbar>
      {/*<FilterButton />*/}
      <SelectColumnsButton />
      {role && role === RegistrationTypeEnum.SUPER_ADMIN ? <AddCompanyButton /> : null}
    </TopToolbar>
  );
}

export default ListActions;
