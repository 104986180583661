import { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';

import { filtersDataProvider } from '@/dataProvider';
import { ITransactionsStatus } from '@/types';

function TransactionsStatusAutocompleteInput(): JSX.Element {
  const [statuses, setStatuses] = useState<ITransactionsStatus[]>([]);

  const getFilters = async (): Promise<ITransactionsStatus[]> => {
    const statusFilters =
      await filtersDataProvider.getFilters<ITransactionsStatus[]>('Transactions');
    setStatuses(statusFilters);

    return statusFilters;
  };

  useEffect(() => {
    void getFilters();
  }, []);

  return (
    <AutocompleteInput
      label="resources.transactions.status"
      source="status"
      optionValue="id"
      choices={statuses}
      sx={{ minWidth: 300 }}
    />
  );
}

export default TransactionsStatusAutocompleteInput;
