import { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';

import { filtersDataProvider } from '@/dataProvider';
import { IEmployeesStatus } from '@/types';

function CompaniesAutocompleteInput(): JSX.Element {
  const [statuses, setStatuses] = useState<IEmployeesStatus[]>([]);

  const getFilters = async (): Promise<IEmployeesStatus[]> => {
    const statusFilters = await filtersDataProvider.getFilters<IEmployeesStatus[]>('Employees');
    setStatuses(statusFilters);

    return statusFilters;
  };

  useEffect(() => {
    void getFilters();
  }, []);

  return (
    <AutocompleteInput
      label="resources.employees.status"
      source="status"
      optionValue="id"
      choices={statuses}
      sx={{ minWidth: 200 }}
    />
  );
}

export default CompaniesAutocompleteInput;
