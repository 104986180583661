import { Box, TextField, Button } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useFieldArray } from 'react-hook-form';

import { TFormMethods } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/EditCompanyForm';

interface IAdminsSectionProps {
  methods: TFormMethods;
}

function AdminsSection({ methods }: IAdminsSectionProps): JSX.Element {
  const t = useTranslate();

  const {
    control,
    register,
    formState: { errors },
  } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'admins',
  });

  return (
    <>
      {fields.map((item, index) => (
        <Box
          key={item.id}
          display={{ xs: 'block', sm: 'flex', width: `${index > 0 ? '100%' : '89%'}` }}
        >
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextField
              {...register(`admins.${index}.first_name`, {
                required: t('resources.company.form.add_company_errors.required'),
              })}
              label={t('resources.company.form.add_company.admin_name')}
              error={!!errors.admins?.[index]?.first_name}
              fullWidth
              helperText={errors.admins?.[index]?.first_name?.message || ''}
              margin="normal"
              required
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextField
              {...register(`admins.${index}.last_name`, {
                required: t('resources.company.form.add_company_errors.required'),
              })}
              label={t('resources.company.form.add_company.admin_last_name')}
              error={!!errors.admins?.[index]?.last_name}
              fullWidth
              helperText={errors.admins?.[index]?.last_name?.message || ''}
              margin="normal"
              required
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
            <TextField
              {...register(`admins.${index}.email`, {
                required: t('resources.company.form.add_company_errors.required'),
              })}
              label={t('resources.company.form.add_company.admin_email')}
              error={!!errors.admins?.[index]?.email}
              fullWidth
              helperText={errors.admins?.[index]?.email?.message || ''}
              margin="normal"
              required
            />
          </Box>
          {index > 0 ? (
            <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 0 0 4px' }}>
              <Button sx={{ color: '#d32f2f' }} onClick={() => remove(index)}>
                {t('resources.company.form.add_company.remove_admin')}
              </Button>
            </Box>
          ) : null}
        </Box>
      ))}
      <Button onClick={() => append({ first_name: '', last_name: '', email: '', id: '' })}>
        {t('resources.company.form.add_company.add_admin')}
      </Button>
    </>
  );
}

export default AdminsSection;
