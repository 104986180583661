import { GetListParams, GetListResult } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { ICompany, ICompaniesRequestParams } from '@/types';

export const getList = async (data?: GetListParams): Promise<GetListResult<ICompany>> => {
  try {
    const requestData: ICompaniesRequestParams = {
      filter: data?.filter.status,
      search: data?.filter.company_name,
      page: data?.pagination.page,
      order: data?.sort.order,
      take: data?.pagination.perPage,
    };

    const { data: responseData } = await API_HANDLERS.COMPANY.GET_LIST(requestData);

    return Promise.resolve({
      data: responseData.data,
      total: responseData.meta.itemCount,
      pageInfo: {
        hasNextPage: responseData.meta.hasNextPage,
        hasPreviousPage: responseData.meta.hasPreviousPage,
      },
    });
  } catch (error) {
    return Promise.reject(new Error(`Get Employees List Error: ${error}`));
  }
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const getCompany = async params => {
  try {
    const requestData: { id: string } = {
      id: params.id,
    };

    const { data: responseData } = await API_HANDLERS.COMPANY.GET_COMPANY(requestData);

    return Promise.resolve({
      data: responseData,
      total: 0,
    });
  } catch (error) {
    return Promise.reject(new Error(`Get company Error: ${error}`));
  }
};
