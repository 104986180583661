import { type Translate } from 'react-admin';

export interface IMonthOption {
  name: string;
  id: string;
}

export function generateMonthOptions(translate: Translate): IMonthOption[] {
  const months: string[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  return months.map(
    (month: string, index: number): IMonthOption => ({
      name: translate(`resources.dashboard.months.${month}`),
      id: (index + 1).toString().padStart(2, '0'),
    }),
  );
}
