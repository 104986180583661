import {
  Admin,
  Resource,
  localStorageStore,
  useStore,
  StoreContextProvider,
  CustomRoutes,
} from 'react-admin';
import { Route } from 'react-router';

import authProvider from '@/authProvider';
import { dataProvider } from '@/dataProvider';
import { getTranslateProvider } from '@/i18n/getTranslateProvider';
import { themes, ThemeName } from '@/themes/themes';
import companies from '@/view/companies';
import { Dashboard } from '@/view/dashboard';
import employees from '@/view/employees';
import { ChangeForgotPassword, ForgotPassword, Layout, Login } from '@/view/layout';
import settings from '@/view/settings';
import transactions from '@/view/transactions';
import users from '@/view/users';

const i18nProvider = getTranslateProvider();

const store = localStorageStore(undefined, 'ECommerce');

const App = () => {
  const [themeName] = useStore<ThemeName>('themeName', 'soft');
  const lightTheme = themes.find(theme => theme.name === themeName)?.light;
  const darkTheme = themes.find(theme => theme.name === themeName)?.dark;

  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        store={store}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        disableTelemetry
        lightTheme={lightTheme}
        darkTheme={darkTheme}
        defaultTheme="light"
      >
        <CustomRoutes noLayout>
          <Route path="/change-forgot-password" element={<ChangeForgotPassword />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </CustomRoutes>
        <Resource name="companies" {...companies} />
        <Resource name="employees" {...employees} />
        <Resource name="users" {...users} />
        <Resource name="change-password" {...settings} />
        <Resource name="transactions" {...transactions} />
      </Admin>
    </>
  );
};

const AppWrapper = () => (
  <StoreContextProvider value={store}>
    <App />
  </StoreContextProvider>
);

export default AppWrapper;
