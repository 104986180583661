import { API_PATHS } from '@/api';
import { postData, getData } from '@/api/crudHandlers';
import {
  ISignInRequestParams,
  ISignInResponseSuccess,
  TAxiosResponseSuccess,
  IChangeForgotPasswordRequestParams,
  IChangePasswordRequestParams,
} from '@/types';

export const authHandlers = {
  SIGN_IN(data: ISignInRequestParams): TAxiosResponseSuccess<ISignInResponseSuccess> {
    return postData<ISignInResponseSuccess>(API_PATHS.AUTH.SIGN_IN._, {
      data,
      authorizeRequest: false,
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    });
  },
  SIGN_OUT(): TAxiosResponseSuccess<never> {
    return getData<never>(API_PATHS.AUTH.SIGN_OUT._, {
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });
  },
  FORGOT_PASSWORD(email: string): TAxiosResponseSuccess<{ message: string }> {
    return getData<{ message: string }>(API_PATHS.AUTH.FORGOT_PASSWORD._, {
      params: {
        email,
      },
    });
  },
  CHANGE_FORGOT_PASSWORD({
    token,
    email,
    password,
    confirmPassword,
  }: IChangeForgotPasswordRequestParams): TAxiosResponseSuccess<any> {
    return postData<any>(
      API_PATHS.AUTH.CHANGE_FORGOT_PASSWORD._,
      {
        data: {
          email,
          password,
          confirmPassword,
        },
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      },
      token,
    );
  },
  CHANGE_PASSWORD(data: IChangePasswordRequestParams): TAxiosResponseSuccess<{
    message: string;
  }> {
    return postData<{
      message: string;
    }>(API_PATHS.AUTH.CHANGE_PASSWORD._, {
      data: {
        ...data,
      },
    });
  },
} as const;
