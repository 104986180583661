import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, IconButton } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { ITransactionsResponseData } from '@/types';

function DownloadPDF(): JSX.Element {
  const { application_link } = useRecordContext<ITransactionsResponseData>();

  const handleOpenPdf = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ marginRight: '30px' }}>
      <IconButton
        color="primary"
        disabled={!application_link}
        onClick={() => application_link && handleOpenPdf(application_link)}
      >
        <PictureAsPdfIcon />
      </IconButton>
    </Box>
  );
}

export default DownloadPDF;
