export const AuthStatuses = {
  Pending: 'pending',
  Unauthorized: 'unauthorized',
  Locked: 'locked',
  CompanyAdminRegistering: 'companyAdminRegistering',
  CompanyAdminAuthorized: 'companyAdminAuthorized',
  SuperAdminRegistering: 'superAdminRegistering',
  SuperAdminAuthorized: 'superAdminAuthorized',
  SessionExpired: 'sessionExpired',
  Unconfirmed: 'unconfirmed',
} as const;

export type AuthStatus = (typeof AuthStatuses)[keyof typeof AuthStatuses];
