import { API_HANDLERS } from '@/api';

export const uploadContract = async (requestData: FormData): Promise<string> => {
  try {
    const { data: responseData } = await API_HANDLERS.COMPANY.UPLOAD_CONTRACT(requestData);
    const location = await responseData.url;
    return location;
  } catch (error) {
    return Promise.reject(new Error(`Upload Contract Error: ${error}`));
  }
};
