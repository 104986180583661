import { BulkActivateButton } from '@/view/employees/EmployeesList/components/BulkActivateButton';
import { BulkTerminateButton } from '@/view/employees/EmployeesList/components/BulkTerminateButton';

function BulkActions(): JSX.Element {
  return (
    <>
      <BulkActivateButton />
      <BulkTerminateButton />
    </>
  );
}

export default BulkActions;
