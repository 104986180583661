import { AuthStatus } from '@/api/authStatuses';

export type NotifyErrorEventPayload = {
  message: string;
};

export type MessagePayload = NotifyErrorEventPayload;

export interface MediatedService<Message extends string> {
  receive(message: Message, payload?: MessagePayload): void;
}

export abstract class Mediator<Messages extends string> {
  protected services: Set<MediatedService<Messages>> = new Set();

  abstract add(service: MediatedService<Messages>): void;

  abstract notify(
    message: Messages,
    sender: MediatedService<Messages>,
    payload?: MessagePayload,
  ): void;
}

class ApiServicesMediator extends Mediator<AuthStatus> {
  protected services = new Set<MediatedService<AuthStatus>>();

  add(service: MediatedService<AuthStatus>): void {
    this.services.add(service);
  }

  notify(message: AuthStatus, sender: MediatedService<AuthStatus>, payload?: MessagePayload): void {
    this.services.forEach(service => {
      if (service !== sender) {
        service.receive(message, payload);
      }
    });
  }
}

export default new ApiServicesMediator();
