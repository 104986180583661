import { type Translate } from 'react-admin';

import { IEmployeesCheckUploadXLSError } from '@/types';

export function formatEmployeesErrors(
  errors: IEmployeesCheckUploadXLSError[],
  translate: Translate,
): string {
  return errors
    .map(({ message, raw }: IEmployeesCheckUploadXLSError): string => {
      return raw !== 0
        ? translate(`resources.${message}`, { field: raw })
        : translate(`resources.${message}`);
    })
    .join(' ');
}
