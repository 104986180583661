import { type Translate } from 'react-admin';
import { z, ZodSchema } from 'zod';

export function getChangeForgotPasswordValidationSchema(t: Translate) {
  const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

  const zodSchema: ZodSchema = z
    .object({
      newPassword: z
        .string()
        .min(1, t('forgot_password.errors.required_field'))
        .regex(passwordRegex, t('resources.settings.change_password_form.errors.password_rule')),
      confirmNewPassword: z.string().min(1, t('forgot_password.errors.required_field')),
    })
    .refine(data => data.newPassword === data.confirmNewPassword, {
      message: t('forgot_password.errors.password_doesnt_match'),
      path: ['confirmNewPassword'],
    });

  return zodSchema;
}
