import { DataProvider } from 'react-admin';

import { DATA_PROVIDER_RESOURCES } from '@/constants';
import { adminsDataProvider } from '@/dataProvider/admins-data-provider';
import { companiesDataProvider } from '@/dataProvider/companiesDataProvider';
import { employeesDataProvider } from '@/dataProvider/employeesDataProvider';
import { transactionsDataProvider } from '@/dataProvider/transactionsDataProvider';
import { ICompanyRequestParams } from '@/types';

const defaultDataProvider: DataProvider = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  create: (resource, params) => {
    if (resource === DATA_PROVIDER_RESOURCES.COMPANY._) {
      return companiesDataProvider.createCompany(
        params.data as ICompanyRequestParams,
        () => ({}),
        () => ({}),
      );
    }
    return Promise.resolve({ data: { id: 0 } });
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  delete: () => Promise.resolve({ data: {} }),
  deleteMany: () => Promise.resolve({}),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  getList: (resource, params) => {
    switch (resource) {
      case DATA_PROVIDER_RESOURCES.EMPLOYEES._:
        return employeesDataProvider.getList(params);
      case DATA_PROVIDER_RESOURCES.COMPANY._:
        return companiesDataProvider.getList(params);
      case DATA_PROVIDER_RESOURCES.ADMINS._:
        return adminsDataProvider.getList(params);
      case DATA_PROVIDER_RESOURCES.TRANSACTIONS._:
        return transactionsDataProvider.getList(params);
      default:
        return Promise.resolve({ data: [], total: 0 });
    }
  },
  getMany: () => Promise.resolve({ data: [] }),
  getManyReference: () => Promise.resolve({ data: [], total: 0 }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  getOne: (resource, params) => {
    if (resource === DATA_PROVIDER_RESOURCES.COMPANY._) {
      return companiesDataProvider.getCompany(params);
    }

    return Promise.resolve({ data: {} });
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  update: () => Promise.resolve({ data: {} }),
  updateMany: (resource, params) => {
    if (resource === DATA_PROVIDER_RESOURCES.EMPLOYEES._) {
      return employeesDataProvider.terminateEmployees(params);
    }

    return Promise.resolve({});
  },
};

export default defaultDataProvider;
