import {
  LoadingIndicator,
  // LocalesMenuButton
} from 'react-admin';

import { ThemeSwapper } from '@/themes/ThemeSwapper';

function AppBarToolbar(): JSX.Element {
  return (
    <>
      {/*<LocalesMenuButton />*/}
      <ThemeSwapper />
      <LoadingIndicator />
    </>
  );
}

export default AppBarToolbar;
