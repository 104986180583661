import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

import { ImportCSVButton } from '@/view/employees/EmployeesList/components/ImportCSVButton';

interface IEmployeesEmptyProps {
  text?: string;
  handleOpenImportCSVModal: () => void;
}

function EmployeesEmpty({ text, handleOpenImportCSVModal }: IEmployeesEmptyProps): JSX.Element {
  const t = useTranslate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '80vh', width: '80vw' }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h6">
          {/* eslint-disable-next-line no-restricted-syntax */}
          {text || t('resources.employees.employees_empty')}
        </Typography>
        <ImportCSVButton handleOpenImportCSVModal={handleOpenImportCSVModal} />
      </Box>
    </Box>
  );
}

export default EmployeesEmpty;
