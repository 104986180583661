export interface IEmployees {
  average_salary: number;
  first_name: string;
  full_name: string;
  id: string;
  itn: string;
  last_name: string;
  last_update: string | null;
  status: string;
}

export interface IEmployeesMeta {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  itemCount: number;
  page: number;
  pageCount: number;
  take: number;
}

export interface IEmployeesResponseParams {
  data: IEmployees[];
  meta: IEmployeesMeta;
}

export interface IEmployeesGetListParams {
  data: IEmployees[];
  total: number;
}

export enum EmployeesStatusEnum {
  ACTIVE = 'Активний',
  TERMINATED = 'Призупинений',
  NOT_REGISTERED = 'Незареєстрований',
}

export interface IEmployeesStatus {
  id: EmployeesStatusEnum;
  name: EmployeesStatusEnum;
}

export interface IEmployeesUploadXLSResponseParams {
  $metadata: {
    attempts: number;
    httpStatusCode: number;
    requestId: string;
    totalRetryDelay: number;
  };
  MD5OfMessageBody: string;
  MessageId: string;
}

export enum EmployeesCheckUploadXLSStatusEnum {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export interface IEmployeesCheckUploadXLSError {
  raw: number;
  message: string;
}

export interface IEmployeesCheckUploadXLSResponseParams {
  errors: IEmployeesCheckUploadXLSError[];
  id: string;
  status: EmployeesCheckUploadXLSStatusEnum;
}
