import { API_HANDLERS } from '@/api';

export const uploadLogo = async (data: FormData): Promise<string> => {
  try {
    const { data: responseData } = await API_HANDLERS.COMPANY.UPLOAD_LOGO(data);
    const location = await responseData.Location;

    return location;
  } catch (error) {
    return Promise.reject(new Error(`Upload Logo Error: ${error}`));
  }
};
