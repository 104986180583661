import { Box, useMediaQuery, Theme } from '@mui/material';
import {
  AppBar,
  // TitlePortal,
} from 'react-admin';

import { AppBarToolbar } from '@/view/layout/AppBarToolbar';
import { Logo } from '@/view/layout/Logo';

function CustomAppBar(): JSX.Element {
  const isLargeEnough = useMediaQuery<Theme>(theme => theme.breakpoints.up('sm'));

  return (
    <AppBar color="secondary" toolbar={<AppBarToolbar />}>
      {/*<TitlePortal />*/}
      <Box sx={{ width: '25%', height: '1px' }} />
      {isLargeEnough ? <Logo /> : null}
      {isLargeEnough ? <Box component="span" sx={{ flex: 1 }} /> : null}
    </AppBar>
  );
}

export default CustomAppBar;
