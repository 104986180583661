import { AccountBalanceWallet, CurrencyExchange, Paid, PeopleAlt } from '@mui/icons-material';
import { Box, type SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';

import { Loader } from '@/components/Loader';
import { dashboardDataProvider } from '@/dataProvider';
import { useGetUserCompanyId, useGetUserRole } from '@/hooks';
import { IDashboardRequestParams, IDashboardResponseParams, RegistrationTypeEnum } from '@/types';
import {
  getCurrentDayNumber,
  getCurrentMonthNumber,
  getCurrentYearNumber,
  getLastDayOfMonth,
} from '@/utils';
import {
  CardValue,
  ChartMonthSelect,
  ChartYearSelect,
  CompaniesSelect,
  OperationsChart,
} from '@/view/dashboard/Dashboard/components';
import {
  generateMonthOptions,
  type IMonthOption,
} from '@/view/dashboard/Dashboard/components/ChartMonthSelect/utils';
import { DashboardEmpty } from '@/view/dashboard/DashboardEmpty';

const dashboardDefaultValues: IDashboardResponseParams = {
  balance: '',
  employees: 0,
  graph: [],
  transactions: 0,
  withdrawals: '',
};

function Dashboard(): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();

  const role = useGetUserRole();
  const userCompanyId = useGetUserCompanyId();
  const today = `${getCurrentDayNumber()}`;
  const monthOptions: IMonthOption[] = generateMonthOptions(t);

  const [selectedMonth, setSelectedMonth] = useState<string>(
    `${monthOptions[getCurrentMonthNumber() - 1].id}`,
  );
  const [selectedYear, setSelectedYear] = useState<string>(`${getCurrentYearNumber()}`);
  const [selectedCompany, setSelectedCompany] = useState<string>('all');
  const [dashboardData, setDashboardData] =
    useState<IDashboardResponseParams>(dashboardDefaultValues);
  const [loading, setLoading] = useState<boolean>(true);
  const [showErrorScreen, setShowErrorScreen] = useState<boolean>(false);

  const onError = (errorMsg: string): void => {
    if (errorMsg === 'End date cannot be in the future.') {
      notify('resources.dashboard.errors.future_date', {
        type: 'error',
      });
    }
    setLoading(false);
    setShowErrorScreen(true);
  };

  const getDashboardData = async (
    requestData: IDashboardRequestParams,
  ): Promise<IDashboardResponseParams> => {
    setLoading(true);
    setShowErrorScreen(false);
    const data = await dashboardDataProvider.getDashboardData({ requestData, onError });
    setDashboardData(data);
    setLoading(false);

    return data;
  };

  const handleMonthChange = (event: SelectChangeEvent): void => {
    setSelectedMonth(event.target.value);
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const lastDayOfMonth = getLastDayOfMonth(`${selectedYear}-${selectedMonth}-${today}`);
      const day = lastDayOfMonth.getDate();
      const formattedDay = day < 10 ? `0${day}` : day.toString();
      const month = lastDayOfMonth.getMonth() + 1;
      const formattedMonth = month < 10 ? `0${month}` : month.toString();
      const year = lastDayOfMonth.getFullYear();

      const endDate = `${year}-${formattedMonth}-${formattedDay}`;

      void getDashboardData({
        filter: {
          companyId: selectedCompany,
          startDate: `${selectedYear}-${selectedMonth}-01`,
          endDate: endDate,
          month: `${selectedYear}-${selectedMonth}-01`,
        },
      });
    }
  }, [selectedCompany, selectedYear, selectedMonth]);

  const loader: JSX.Element = (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '70vh', width: '70vw' }}
    >
      <Loader />
    </Box>
  );

  const showContent = (): JSX.Element => {
    switch (true) {
      case loading:
        return loader;
      case showErrorScreen:
        return <DashboardEmpty />;
      case !loading && !showErrorScreen:
        return (
          <Box>
            <Box sx={{ display: 'flex', margin: '16px 0' }}>
              <Box sx={{ minWidth: '330px', height: '88px', margin: '0 8px 0 0' }}>
                <CardValue
                  icon={PeopleAlt}
                  redirect={'/employees'}
                  title={t('resources.dashboard.cards.employees')}
                  value={`${dashboardData.employees}`}
                />
              </Box>
              <Box sx={{ minWidth: '330px', height: '88px', margin: '0 8px 0 8px' }}>
                <CardValue
                  icon={Paid}
                  redirect={'/transactions'}
                  title={t('resources.dashboard.cards.transactions')}
                  value={`${dashboardData.transactions}`}
                />
              </Box>
              <Box sx={{ minWidth: '330px', height: '88px', margin: '0 8px 0 8px' }}>
                <CardValue
                  icon={CurrencyExchange}
                  redirect={'/transactions'}
                  title={t('resources.dashboard.cards.withdrawals')}
                  value={`${dashboardData.withdrawals}`}
                />
              </Box>
              <Box sx={{ minWidth: '330px', height: '88px', margin: '0 0 0 8px' }}>
                <CardValue
                  icon={AccountBalanceWallet}
                  redirect={
                    role === RegistrationTypeEnum.COMPANY_ADMIN
                      ? `/companies/${userCompanyId}/show`
                      : '/companies'
                  }
                  title={t('resources.dashboard.cards.balance')}
                  value={`${dashboardData.balance}`}
                />
              </Box>
            </Box>

            <Box sx={{ margin: '8px 0' }}>
              <OperationsChart
                endDate={`${selectedYear}-${selectedMonth}-${today}`}
                graph={dashboardData.graph}
              />
            </Box>
          </Box>
        );
      default:
        return loader;
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {role === RegistrationTypeEnum.SUPER_ADMIN ? (
          <Box sx={{ width: 300, marginRight: '15px' }}>
            <CompaniesSelect
              selectedCompany={selectedCompany}
              setSelectedCompany={setSelectedCompany}
            />
          </Box>
        ) : null}
        <Box sx={{ width: 300, marginRight: '15px' }}>
          <ChartMonthSelect
            monthOptions={monthOptions}
            selectedMonth={selectedMonth}
            handleMonthChange={handleMonthChange}
          />
        </Box>
        <Box sx={{ width: 300, marginRight: '15px' }}>
          <ChartYearSelect setSelectedYear={setSelectedYear} />
        </Box>
      </Box>
      {showContent()}
    </>
  );
}

export default Dashboard;
