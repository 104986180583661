import { UpdateManyParams } from 'react-admin';

import { API_HANDLERS } from '@/api';
import { IEmployeesTerminateRequestParams, IEmployeesTerminateRequestData } from '@/types';

export const terminateEmployees = async (
  data: UpdateManyParams<IEmployeesTerminateRequestData>,
): Promise<Record<string, never>> => {
  try {
    const isTerminated = data.data.is_terminated;
    const requestData: IEmployeesTerminateRequestParams = {
      employees: data.ids.map(id => ({ id, is_terminated: !!isTerminated })),
    };
    await API_HANDLERS.EMPLOYEES.TERMINATE(requestData);

    return Promise.resolve({});
  } catch (error) {
    return Promise.reject(new Error(`Terminate Employees Error: ${error}`));
  }
};
