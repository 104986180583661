import { API_HANDLERS } from '@/api';
import { ICompanyRequestParams } from '@/types';

export const createCompany = async (
  data: ICompanyRequestParams,
  onSuccess: () => void,
  // eslint-disable-next-line
  onError: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.COMPANY.CREATE_COMPANY(data);

    onSuccess();
    return response && true;
  } catch (error) {
    onError(error);
    return Promise.reject(new Error(`Create Company Error: ${error}`));
  }
};
