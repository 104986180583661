import { AuthStatus } from '@/api/authStatuses';

enum AuthEventingChannel {
  USER_LOGIN = 'userLogin',
  USER_LOGOUT = 'userLogout',
  USER_SESSION_EXPIRED = 'userSessionExpired',
}

export class AuthDataEvent {
  constructor(public readonly authStatus: AuthStatus) {}
}

export class UserLoginEvent extends AuthDataEvent {}
export class UserLogoutEvent extends AuthDataEvent {}
export class UserSessionExpiredEvent extends AuthDataEvent {}

export type AuthEvents = {
  [AuthEventingChannel.USER_LOGIN]: UserLoginEvent;
  [AuthEventingChannel.USER_LOGOUT]: UserLogoutEvent;
  [AuthEventingChannel.USER_SESSION_EXPIRED]: UserSessionExpiredEvent;
};
