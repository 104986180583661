import { Card, Box, Typography, Divider, type SvgIconTypeMap } from '@mui/material';
import { type OverridableComponent } from '@mui/types';
import { createElement, ReactNode } from 'react';
import { Link, type To } from 'react-router-dom';

interface ICardWithIconProps {
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & { muiName: string };
  to: To;
  title?: string;
  subtitle?: ReactNode;
  children?: ReactNode;
}

function CardWithIcon({ icon, title, subtitle, to, children }: ICardWithIconProps): JSX.Element {
  return (
    <Card
      sx={{
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
          textDecoration: 'none',
          color: 'inherit',
        },
      }}
    >
      <Link to={to}>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            padding: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: 'secondary.main',
            },
            '&:before': {
              position: 'absolute',
              top: '50%',
              left: 0,
              display: 'block',
              content: `''`,
              height: '200%',
              aspectRatio: '1',
              transform: 'translate(-30%, -60%)',
              borderRadius: '50%',
              backgroundColor: 'secondary.main',
              opacity: 0.15,
            },
          }}
        >
          <Box width="3em" className="icon">
            {createElement(icon, { fontSize: 'large' })}
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">{title}</Typography>
            <Typography variant="h5" component="h2">
              {subtitle ? subtitle : ' '}
            </Typography>
          </Box>
        </Box>
      </Link>
      {children ? <Divider /> : null}
      {children}
    </Card>
  );
}

export default CardWithIcon;
