import { useState } from 'react';
import { List, useListContext } from 'react-admin';

import { useGetUserRole } from '@/hooks';
import { ITransactionsFilterParams, RegistrationTypeEnum } from '@/types';
import { TransactionsEmpty } from '@/view/transactions/TransactionsEmpty';
import {
  TabbedDatagrid,
  ListActions,
  adminTransactionsFilters,
  superAdminTransactionsFilters,
} from '@/view/transactions/TransactionsList/components';
import { SignWithdrawalOrdersDialog } from '@/view/transactions/TransactionsList/components/SignWithdrawalOrdersDialog';

function TransactionsList(): JSX.Element {
  const role = useGetUserRole();

  const [showOrdersDialog, setShowOrdersDialog] = useState(false);
  const [listFilters, setListFilters] = useState<ITransactionsFilterParams>();

  const ListContext = () => {
    const { filterValues } = useListContext();
    setListFilters(filterValues);

    return null;
  };

  return (
    <>
      <List
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filters={
          role === RegistrationTypeEnum.SUPER_ADMIN
            ? superAdminTransactionsFilters
            : adminTransactionsFilters
        }
        actions={<ListActions openSignOrdersDialog={() => setShowOrdersDialog(true)} />}
        empty={<TransactionsEmpty />}
      >
        <ListContext />
        <TabbedDatagrid />
      </List>
      {showOrdersDialog && (
        <SignWithdrawalOrdersDialog
          onClose={() => setShowOrdersDialog(false)}
          listFilters={listFilters}
        />
      )}
    </>
  );
}

export default TransactionsList;
