import {
  Divider,
  // Theme,
  // useMediaQuery,
} from '@mui/material';
import { DatagridConfigurable, DateField, NumberField, TextField } from 'react-admin';

import { BulkActions } from '@/view/employees/EmployeesList/components/BulkActions';
// import { MobileGrid } from '@/view/employees/EmployeesList/components/MobileGrid';

function TabbedDatagrid(): JSX.Element {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <>
      <Divider />
      <DatagridConfigurable rowClick={false} bulkActionButtons={<BulkActions />}>
        <TextField source="first_name" label="resources.employees.first_name" sortable={false} />
        <TextField source="last_name" label="resources.employees.last_name" sortable={false} />
        <TextField source="itn" label="resources.employees.itn" sortable={false} />
        <TextField source="status" label="resources.employees.status" sortable={false} />
        <DateField source="last_update" label="resources.employees.last_update" showTime />
        <NumberField
          sortable={false}
          source="average_salary"
          label="resources.employees.average_salary"
          options={{ style: 'currency', currency: 'UAH' }}
        />
        {/*<EditButton />*/}
      </DatagridConfigurable>
    </>
  );
}

export default TabbedDatagrid;
