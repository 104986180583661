import { zodResolver } from '@hookform/resolvers/zod';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab, Tabs } from '@mui/material';
import { type Dispatch, type SetStateAction, type SyntheticEvent, useState } from 'react';
import { useTranslate, useRedirect, useNotify } from 'react-admin';
import { type UseFormReturn, type SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { companiesDataProvider } from '@/dataProvider';
import { ICompanyRequestParams, IAdmin } from '@/types';
import {
  CompanyInfoTab,
  CompanySettingsTab,
} from '@/view/companies/CompaniesCreate/AddCompanyForm/FormTabs';
import { getCompanyFormValidationSchema } from '@/view/companies/CompaniesCreate/AddCompanyForm/validation';

export type TActiveTab = 'company_info' | 'company_settings';
export type TSetActiveTab = Dispatch<SetStateAction<TActiveTab>>;
export type TAddCompanyFormData = Omit<ICompanyRequestParams, 'admins'> & { admins: IAdmin[] };
export type TFormMethods = UseFormReturn<TAddCompanyFormData>;

export interface IAddCompanyFormProps<FormValuesType> {
  formDefaultValues: FormValuesType;
}

function AddCompanyForm({
  formDefaultValues,
}: IAddCompanyFormProps<TAddCompanyFormData>): JSX.Element {
  const t = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();

  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TActiveTab>('company_info');
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [logo, setLogo] = useState<string>('');
  const [contractUrl, setContractUrl] = useState<string>('');
  const [contractFileName, setContractFileName] = useState<string>('');

  const validationSchema = getCompanyFormValidationSchema(t);
  const methods = useForm<TAddCompanyFormData>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: formDefaultValues,
    resolver: zodResolver(validationSchema),
  });
  const {
    getValues,
    formState: { errors },
  } = methods;

  const handleResetForm = (): void => {
    methods.reset();
    setSelectedDates([]);
    setActiveTab('company_info');
    redirect('/companies');
  };

  const isNextBtnDisabled =
    !getValues('legal_name') ||
    !getValues('company_name') ||
    !getValues('legal_entity_id') ||
    !getValues('admins.0.first_name') ||
    !getValues('admins.0.last_name') ||
    !getValues('admins.0.email') ||
    Object.keys(errors).length > 0;

  const handleChangeTab = (_event: SyntheticEvent, newValue: TActiveTab): void => {
    if (newValue === 'company_settings' && isNextBtnDisabled) {
      console.log('Form is not valid. Complete all required fields correctly.');
      return;
    }
    setActiveTab(newValue);
  };

  const onSubmit: SubmitHandler<TAddCompanyFormData> = async (data): Promise<void> => {
    setLoading(true);

    const pay_days = selectedDates.map(date => date.toISOString());

    const result = await companiesDataProvider.createCompany(
      {
        ...data,
        pay_days,
        logo,
        contract_url: contractUrl,
      },
      () => {
        setLoading(false);
      },
      // eslint-disable-next-line
      (error: any) => {
        if (error && error?.response?.data?.message) {
          if (error.response.data.message === 'company.errors.secret_manager_key_exist') {
            setActiveTab('company_info');
            notify('resources.company.form.add_company_errors.secret_manager_key_exist', {
              type: 'error',
            });
          } else if (error.response.data.message === 'company.errors.incorrect_salt_or_sign') {
            notify('resources.company.form.add_company_errors.invalid_name', {
              type: 'error',
            });
          } else if (error.response.data.message.includes('Invalid name. Must be a valid name')) {
            notify('resources.company.form.add_company_errors.invalid_name', {
              type: 'error',
            });
          } else if (
            error.response.data.message.includes('The operation failed because the secret')
          ) {
            notify('resources.company.form.add_company_errors.secret_merchant', {
              type: 'error',
            });
          } else if (error.response.data.message === 'company.errors.nullable_merchant_fields') {
            notify('resources.company.form.add_company_errors.nullable_merchant_fields', {
              type: 'error',
            });
          } else if (
            error.response.data.message === 'company.errors.merchant_name_contains_space'
          ) {
            notify('resources.company.form.add_company_errors.merchant_name_contains_space', {
              type: 'error',
            });
          }
        }
        setLoading(false);
      },
    );

    if (result) {
      handleResetForm();
    }
  };

  return (
    <Box sx={{ width: 975 }}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TabContext value={activeTab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleChangeTab} aria-label="company form tabs">
                <Tab
                  label={t('resources.company.form.add_company.company_info')}
                  value="company_info"
                />
                <Tab
                  label={t('resources.company.form.add_company.company_settings')}
                  value="company_settings"
                />
              </Tabs>
            </Box>
            <TabPanel value="company_info">
              <CompanyInfoTab
                methods={methods}
                isNextBtnDisabled={isNextBtnDisabled}
                setActiveTab={setActiveTab}
                setLogo={setLogo}
                contractUrl={contractUrl}
                setContractUrl={setContractUrl}
                contractFileName={contractFileName}
                setContractFileName={setContractFileName}
              />
            </TabPanel>
            <TabPanel value="company_settings">
              <CompanySettingsTab
                loading={loading}
                methods={methods}
                setActiveTab={setActiveTab}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                handleResetForm={handleResetForm}
              />
            </TabPanel>
          </TabContext>
        </form>
      </FormProvider>
    </Box>
  );
}

export default AddCompanyForm;
