import { Avatar, Button, Card, CardActions, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Form, required, TextInput, useTranslate, useLogin, useNotify, Link } from 'react-admin';
import { useLocation } from 'react-router-dom';

import loginBackgroundImage from '@/assets/img/loginBackgroundImage.webp';
import FlexipayIcon from '@/assets/svg/flexipay-logo-icon.svg';
import { formatErrorMessage } from '@/utils';

export interface ILoginFormValues {
  email?: string;
  password?: string;
}

export interface ILoginProps {
  authProvider?: () => void;
  previousRoute?: string;
}

function Login(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();

  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleFailure = (error: Error) => {
    setLoading(false);
    const errors = formatErrorMessage(error.message, translate);
    notify(errors, {
      type: 'error',
      messageArgs: {
        _: typeof error === 'string' ? errors : error && error.message ? errors : undefined,
      },
    });
  };

  const handleSubmit = (auth: ILoginFormValues) => {
    setLoading(true);
    // eslint-disable-next-line
    const pathName = location.state ? (location.state as any).nextPathname : '/';

    login(auth, pathName).catch(handleFailure);
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `url(${loginBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.light' }}>
              <img src={FlexipayIcon} alt="FlexipayIcon" width={40} height={40} />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          >
            <strong>FlexiPay</strong>
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                source="email"
                label={translate('auth.email')}
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                source="password"
                label={translate('auth.password')}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
              {loading ? <CircularProgress size={25} thickness={2} /> : translate('auth.sign_in')}
            </Button>
          </CardActions>
          <Box sx={{ display: 'flex', padding: '0 1em 1em 1em', justifyContent: 'center' }}>
            <Link to="/forgot-password">{translate('auth.forgot_password')}</Link>
          </Box>
        </Card>
      </Box>
    </Form>
  );
}

export default Login;
