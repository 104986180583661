import { format, subDays } from 'date-fns';

import { IDashboardGraphItem } from '@/types';
import { getDaysInMonth, getLastDayOfMonth } from '@/utils';
import { aggregateGraphByDay } from '@/view/dashboard/Dashboard/components/OperationsChart/utils/aggregateGraphByDay';

interface ITotalByDay {
  amount: number;
  date: number;
  normalDate: string;
  operations: number;
  total: number;
}

export function getRevenuePerDay(endDate: string, graph: IDashboardGraphItem[]): ITotalByDay[] {
  const lastDay = getLastDayOfMonth(endDate);
  const lastMonthDays = Array.from({ length: getDaysInMonth(endDate) }, (_, i) =>
    subDays(lastDay, i),
  );

  const daysWithRevenue = aggregateGraphByDay(graph);
  return lastMonthDays.map(date => {
    const formattedDate = date.getTime();
    const dayData = daysWithRevenue[format(new Date(date), 'yyyy-MM-dd')] || {
      total: 0,
      amount: 0,
    };

    return {
      amount: Number(dayData.amount.toFixed(2)),
      date: formattedDate,
      normalDate: format(new Date(date), 'dd.MM'),
      operations: dayData.total,
      total: Number(dayData.amount.toFixed(2)),
    };
  });
}
