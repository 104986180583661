import { GetListParams, GetListResult } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { IEmployees, IEmployeesRequestParams } from '@/types';

export const getList = async (data?: GetListParams): Promise<GetListResult<IEmployees>> => {
  try {
    const requestData: IEmployeesRequestParams = {
      filter: {
        status: data?.filter.status,
        companyId: data?.filter.companies,
      },
      search: data?.filter.full_name,
      page: data?.pagination.page,
      order: data?.sort.order,
      take: data?.pagination.perPage,
    };

    const { data: responseData } = await API_HANDLERS.EMPLOYEES.GET_LIST(requestData);

    return Promise.resolve({
      data: responseData.data,
      total: responseData.meta.itemCount,
      pageInfo: {
        hasNextPage: responseData.meta.hasNextPage,
        hasPreviousPage: responseData.meta.hasPreviousPage,
      },
    });
  } catch (error) {
    return Promise.reject(new Error(`Get Employees List Error: ${error}`));
  }
};
