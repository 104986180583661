import BusinessIcon from '@mui/icons-material/Business';

import { CompaniesCreate } from '@/view/companies/CompaniesCreate';
import { CompaniesEdit } from '@/view/companies/CompaniesEdit';
import { CompaniesList } from '@/view/companies/CompaniesList';
import CompanyView from '@/view/companies/CompanyView';

export default {
  create: CompaniesCreate,
  edit: CompaniesEdit,
  list: CompaniesList,
  show: CompanyView,
  icon: BusinessIcon,
  recordRepresentation: 'reference',
};
