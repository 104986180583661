import { Box, Button, Link, TextField, Typography } from '@mui/material';
import {
  type ChangeEvent,
  type Dispatch,
  Fragment,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslate } from 'react-admin';

import { SectionTitle, Separator } from '@/components/form';
import { companiesDataProvider, pspDataProvider } from '@/dataProvider';
import { AdminsSection } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/AdminsSection';
import {
  TFormMethods,
  TSetActiveTab,
} from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/EditCompanyForm';
import { ICompanyCredentialsItemResponseParams, ICompanyCredentialsPerPsp, IPsp } from '@/types';
import { PspUniqueName } from '@/constants';

interface ICompanyInfoTabProps {
  contractFileName: string;
  contractUrl: string;
  isNextBtnDisabled: boolean;
  methods: TFormMethods;
  credentials: ICompanyCredentialsPerPsp;
  setActiveTab: TSetActiveTab;
  setContractFileName: Dispatch<SetStateAction<string>>;
  setContractUrl: Dispatch<SetStateAction<string>>;
  setLogo: Dispatch<SetStateAction<string>>;
  setCredentials: Dispatch<ICompanyCredentialsPerPsp>;
}

function CompanyInfoTab({
  contractFileName,
  contractUrl,
  isNextBtnDisabled,
  methods,
  credentials,
  setActiveTab,
  setContractFileName,
  setContractUrl,
  setLogo,
  setCredentials,
}: ICompanyInfoTabProps): JSX.Element {
  const t = useTranslate();
  const [pspList, setPspList] = useState<IPsp[]>([]);
  const [credentialsList, setCredentialsList] = useState<
    ICompanyCredentialsItemResponseParams[] | null
  >(null);
  const [isCredentialsLoaded, setIsCredentialsLoaded] = useState(false);

  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = methods;

  useEffect(() => {
    register('logo');
  }, [register]);

  useEffect(() => {
    pspDataProvider.getList().then(setPspList);
    companiesDataProvider.getCredentialsList(getValues('id')).then(setCredentialsList);
  }, []);

  useEffect(() => {
    if (!credentialsList) {
      return;
    }

    const companyCredentials = credentialsList.reduce((acc, item) => {
      return { ...acc, [item.psp_id]: item.secrets };
    }, {});

    setCredentials({
      ...credentials,
      ...companyCredentials,
    });

    setIsCredentialsLoaded(true);
  }, [credentialsList]);

  const setCredentialsValue = useCallback(
    (pspId: string, field: string, value: string) => {
      setCredentials({
        ...credentials,
        [pspId]: {
          ...(credentials?.[pspId] || {}),
          [field]: value,
        },
      });
    },
    [credentials],
  );

  const logo = watch('logo');

  const uploadLogoToS3 = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);

    const imageUrl = await companiesDataProvider.uploadLogo(formData);
    setLogo(imageUrl);
  };

  const uploadContractToS3 = async (file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);

    const contractUrl = await companiesDataProvider.uploadContract(formData);
    setContractUrl(contractUrl);
  };

  const handleLogoUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files ? event.target.files[0] : null;
    if (
      file &&
      (file.type === 'image/jpeg' || file.type === 'image/png') &&
      file.size <= 5 * 1024 * 1024
    ) {
      const reader = new FileReader();
      void uploadLogoToS3(file);

      reader.onloadend = () => {
        setValue('logo', reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      alert(t('resources.company.form.add_company_errors.logo_large'));
      event.target.value = '';
    }
  };

  const handleContractUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && file.type === 'application/pdf') {
      setContractFileName(file.name);
      uploadContractToS3(file);
    } else {
      alert(t('resources.company.form.add_company_errors.invalid_contract'));
      event.target.value = '';
    }
  };

  return (
    <Box>
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('legal_name', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            label={t('resources.company.form.add_company.legal_name')}
            error={!!errors.legal_name}
            fullWidth
            helperText={errors.legal_name?.message}
            margin="normal"
            required
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('company_name', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            label={t('resources.company.form.add_company.company_name')}
            error={!!errors.company_name}
            fullWidth
            helperText={errors.company_name?.message}
            margin="normal"
            required
          />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('legal_entity_id', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            label={t('resources.company.form.add_company.legal_entity_id')}
            error={!!errors.legal_entity_id}
            fullWidth
            helperText={errors.legal_entity_id?.message}
            margin="normal"
            required
          />
        </Box>
      </Box>

      <Separator />
      <SectionTitle label={'resources.company.form.add_company.admins'} />
      <AdminsSection methods={methods} />

      <Separator />
      <SectionTitle label={'resources.company.form.add_company.logo'} />

      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
        {logo ? (
          <Box component="img" sx={{ width: 200, height: 200, marginBottom: 2 }} src={logo} />
        ) : (
          <Typography
            sx={{
              alignItems: 'center',
              border: '1px dashed grey',
              display: 'flex',
              height: 200,
              justifyContent: 'center',
              marginBottom: 2,
              width: 200,
            }}
          >
            {t('resources.company.form.add_company.upload_logo_rule')}
          </Typography>
        )}
        <Button variant="outlined" component="label" sx={{ width: 200 }}>
          {t('resources.company.form.add_company.upload_logo')}
          <input type="file" hidden accept="image/jpeg,image/png" onChange={handleLogoUpload} />
        </Button>
      </Box>

      <Separator />
      <SectionTitle label={'resources.company.form.add_company.contract'} />

      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center">
        {contractFileName && contractUrl ? (
          <Link
            href={contractUrl}
            target="_blank"
            rel="noopener"
            variant="subtitle1"
            sx={{ mb: 2 }}
          >
            {contractFileName}
          </Link>
        ) : null}
        <Button variant="outlined" component="label" sx={{ width: 300 }}>
          {t('resources.company.form.add_company.upload_contract')}
          <input type="file" hidden accept="application/pdf" onChange={handleContractUpload} />
        </Button>
      </Box>

      <Separator />

      {(credentialsList?.length === 0 || isCredentialsLoaded) &&
        pspList.map(psp => (
          <Fragment key={psp.id}>
            <SectionTitle
              label={`${t('resources.company.form.add_company.merchant_info')} ${psp.name}`}
              raw={true}
            />
            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
              <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                <TextField
                  value={credentials?.[psp.id]?.merchant_id}
                  onChange={e => setCredentialsValue(psp.id, 'merchant_id', e.target.value)}
                  fullWidth
                  margin="normal"
                  label={t('resources.company.form.add_company.merchant_id')}
                />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                <TextField
                  value={credentials?.[psp.id]?.merchant_key}
                  onChange={e => setCredentialsValue(psp.id, 'merchant_key', e.target.value)}
                  label={t('resources.company.form.add_company.merchant_key')}
                  fullWidth
                  margin="normal"
                />
              </Box>
            </Box>
            {psp.unique_name === PspUniqueName.Platon && (
              <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                  <TextField
                    value={credentials?.[psp.id]?.merchant_balance_key}
                    onChange={e =>
                      setCredentialsValue(psp.id, 'merchant_balance_key', e.target.value)
                    }
                    label={t('resources.company.form.add_company.merchant_balance_key')}
                    fullWidth
                    margin="normal"
                  />
                </Box>
              </Box>
            )}
          </Fragment>
        ))}

      <Box sx={{ mt: 5, display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="contained"
          disabled={isNextBtnDisabled}
          onClick={() => setActiveTab('company_settings')}
        >
          {t('resources.company.form.add_company.next_step')}
        </Button>
      </Box>
    </Box>
  );
}

export default CompanyInfoTab;
