import { makeHttpRequest } from '@/api/httpClient';
import { TAxiosResponseSuccess, IClientConfig } from '@/types';

export function getData<SuccessPayload>(
  url: string,
  config?: IClientConfig,
): TAxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'GET', ...config });
}

export function postData<SuccessPayload>(
  url: string,
  config?: IClientConfig,
  token?: string,
): TAxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'POST', ...config }, token);
}

export function putData<SuccessPayload>(
  url: string,
  config?: IClientConfig,
): TAxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'PUT', ...config });
}

export function patchData<SuccessPayload>(
  url: string,
  config?: IClientConfig,
): TAxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'PATCH', ...config });
}

export function deleteData<SuccessPayload>(
  url: string,
  config?: IClientConfig,
): TAxiosResponseSuccess<SuccessPayload> {
  return makeHttpRequest<SuccessPayload>({ url, method: 'DELETE', ...config });
}
