import { API_HANDLERS } from '@/api';
import { ICompanyUpdateCredentialsRequestParams } from '@/types';

export const editCredentials = async (
  data: ICompanyUpdateCredentialsRequestParams,
  onSuccess?: () => void,
  // eslint-disable-next-line
  onError?: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.COMPANY.UPDATE_CREDENTIALS(data);

    onSuccess && onSuccess();
    return response && true;
  } catch (error) {
    onError && onError(error);
    return Promise.reject(new Error(`Update Company Credentials Error: ${error}`));
  }
};
