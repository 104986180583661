import { type Translate } from 'react-admin';
import { z, ZodSchema } from 'zod';

export function getForgotPasswordValidationSchema(t: Translate) {
  const zodSchema: ZodSchema = z.object({
    email: z
      .string()
      .email({ message: t('forgot_password.errors.invalid_email_address') })
      .nonempty({ message: t('forgot_password.errors.required_field') }),
  });

  return zodSchema;
}
