import { useState } from 'react';
import { List, useNotify, useRefresh, useTranslate } from 'react-admin';

import { employeesDataProvider } from '@/dataProvider';
import { useGetUserRole, usePolling } from '@/hooks';
import { EmployeesCheckUploadXLSStatusEnum, RegistrationTypeEnum } from '@/types';
import { EmployeesEmpty } from '@/view/employees/EmployeesEmpty';
import {
  adminEmployeesFilters,
  ImportCSVModal,
  ListActions,
  superAdminEmployeesFilters,
  TabbedDatagrid,
} from '@/view/employees/EmployeesList/components';
import { formatEmployeesErrors } from '@/view/employees/EmployeesList/utils';

function EmployeesList(): JSX.Element {
  const t = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const role = useGetUserRole();

  const [isImportCSVModalOpen, setIsImportCSVModalOpen] = useState<boolean>(false);
  const [isPollingActive, setIsPollingActive] = useState<boolean>(false);
  const [sqsMessageId, setSqsMessageId] = useState<string>('');

  const handleOpenImportCSVModal = (): void => {
    setIsImportCSVModalOpen(true);
  };

  const handleCloseImportCSVModal = (): void => {
    setIsImportCSVModalOpen(false);
  };

  const handleSQSProgress = async (): Promise<void> => {
    const { status, errors } = await employeesDataProvider.checkUploadEmployees(sqsMessageId);
    if (status === EmployeesCheckUploadXLSStatusEnum.COMPLETED) {
      notify('resources.employees.import_csv_status.success', {
        type: 'success',
      });
      setIsPollingActive(false);
      setSqsMessageId('');
      refresh();
    } else if (status === EmployeesCheckUploadXLSStatusEnum.FAILED) {
      if (errors.length) {
        const errorMessages = formatEmployeesErrors(errors, t);
        notify(errorMessages, {
          type: 'error',
          autoHideDuration: 30000,
        });
      } else {
        notify('resources.employees.import_csv_status.failure', {
          type: 'error',
          autoHideDuration: 10000,
        });
      }
      setIsPollingActive(false);
      setSqsMessageId('');
    }
  };

  usePolling(handleSQSProgress, 10000, isPollingActive);

  return (
    <>
      <List
        sort={{ field: 'last_update', order: 'ASC' }}
        perPage={25}
        filters={
          role === RegistrationTypeEnum.SUPER_ADMIN
            ? superAdminEmployeesFilters
            : adminEmployeesFilters
        }
        actions={<ListActions handleOpenImportCSVModal={handleOpenImportCSVModal} />}
        empty={<EmployeesEmpty handleOpenImportCSVModal={handleOpenImportCSVModal} />}
      >
        <TabbedDatagrid />
      </List>
      <ImportCSVModal
        setSqsMessageId={setSqsMessageId}
        setIsPollingActive={setIsPollingActive}
        isOpen={isImportCSVModalOpen}
        handleClose={handleCloseImportCSVModal}
      />
    </>
  );
}

export default EmployeesList;
