import { Box, Button, TextField, MenuItem, Chip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ukUA } from '@mui/x-date-pickers/locales';
import { uk } from 'date-fns/locale/uk';
import { useEffect, useState, type Dispatch, type KeyboardEvent, type SetStateAction } from 'react';
import { RadioButtonGroupInput, useTranslate } from 'react-admin';

import { SectionTitle, Separator } from '@/components/form';
import {
  TFormMethods,
  TSetActiveTab,
} from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/EditCompanyForm';
import { pspDataProvider } from '@/dataProvider';
import { IPsp } from '@/types';

interface ICompanySettingsTabProps {
  methods: TFormMethods;
  setActiveTab: TSetActiveTab;
  selectedDates: Date[];
  setSelectedDates: Dispatch<SetStateAction<Date[]>>;
  loading: boolean;
  handleResetForm: () => void;
}

const ukraineLocale = ukUA.components.MuiLocalizationProvider.defaultProps.localeText;

function CompanySettingsTab({
  methods,
  setActiveTab,
  selectedDates,
  setSelectedDates,
  loading,
  handleResetForm,
}: ICompanySettingsTabProps): JSX.Element {
  const t = useTranslate();
  const [pspList, setPspList] = useState<IPsp[]>([]);

  const {
    register,
    formState: {
      errors,
      // isValid
    },
    setValue,
    watch,
    getValues,
  } = methods;

  const companyStatus = watch('status');

  const requiredFields =
    !getValues('legal_entity_id') ||
    !getValues('legal_name') ||
    !getValues('company_name') ||
    !getValues('admins.0.first_name') ||
    !getValues('admins.0.last_name') ||
    !getValues('admins.0.email') ||
    !getValues('withdrawal_limit') ||
    !getValues('withdrawal_method') ||
    !getValues('number_of_transactions') ||
    !getValues('min_amount') ||
    !getValues('status');
  const isSubmitBtnDisabled = requiredFields || !selectedDates.length || loading;

  const handleAddPayDay = (date: Date | null): void => {
    if (date && !selectedDates.find(d => d.getTime() === date.getTime())) {
      const timeZoneOffset = date.getTimezoneOffset() * 60000;
      const localDate = new Date(date.getTime() - timeZoneOffset);
      const newDates = [...selectedDates, localDate];
      setSelectedDates(newDates);
      setValue(
        'pay_days',
        newDates.map(d => d.toISOString().split('T')[0]),
      );
    }
  };

  const handleRemovePayDay = (dateToRemove: Date): void => {
    const newDates = selectedDates.filter(date => date.getTime() !== dateToRemove.getTime());
    setSelectedDates(newDates);
    setValue(
      'pay_days',
      newDates.map(d => d.toISOString().split('T')[0]),
    );
  };

  const handlePreviousStep = (): void => {
    setActiveTab('company_info');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault();
  };

  useEffect(() => {
    pspDataProvider.getList().then(setPspList);
  }, []);

  return (
    <LocalizationProvider
      adapterLocale={uk}
      dateAdapter={AdapterDateFns}
      localeText={ukraineLocale}
    >
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('withdrawal_limit', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            type="number"
            label={t('resources.company.form.add_company.withdrawal_limit')}
            error={!!errors.withdrawal_limit}
            fullWidth
            helperText={errors.withdrawal_limit?.message}
            margin="normal"
            required
          />
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('number_of_transactions', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            type="number"
            label={t('resources.company.form.add_company.number_of_transactions')}
            error={!!errors.number_of_transactions}
            fullWidth
            helperText={errors.number_of_transactions?.message}
            margin="normal"
            required
          />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('withdrawal_method', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            defaultValue="Card"
            error={!!errors.withdrawal_method}
            fullWidth
            helperText={errors.withdrawal_method?.message}
            label={t('resources.company.form.add_company.withdrawal_method')}
            margin="normal"
            required
            select
          >
            <MenuItem value="Card">{t('resources.company.form.add_company.card')}</MenuItem>
          </TextField>
        </Box>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('min_amount', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            type="number"
            label={t('resources.company.form.add_company.min_amount')}
            error={!!errors.min_amount}
            fullWidth
            helperText={errors.min_amount?.message}
            margin="normal"
            required
          />
        </Box>
      </Box>

      <Box display={{ xs: 'block', sm: 'flex', width: '50%' }}>
        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
          <TextField
            {...register('status', {
              required: t('resources.company.form.add_company_errors.required'),
            })}
            defaultValue={companyStatus}
            error={!!errors.status}
            fullWidth
            helperText={errors.status?.message}
            label={t('resources.company.status')}
            margin="normal"
            required
            select
          >
            <MenuItem key="active" value="active">
              {t('resources.company.form.add_company.active')}
            </MenuItem>
            <MenuItem key="inactive" value="inactive">
              {t('resources.company.form.add_company.inactive')}
            </MenuItem>
          </TextField>
        </Box>
      </Box>

      <Separator />
      <SectionTitle label="resources.company.psps" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, width: '30%' }}>
          <RadioButtonGroupInput
            label=""
            source="pspId"
            choices={pspList}
            optionText="name"
            optionValue="id"
            row={false}
          />
        </Box>
      </Box>

      <Separator />
      <SectionTitle label="resources.company.form.add_company.payment_days" />
      <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
        <Box flex={1} mr={{ xs: 0, width: '30%' }}>
          <DatePicker
            label={t('resources.company.form.add_company.date_picker')}
            onChange={handleAddPayDay}
            minDate={new Date('2024-01-01')}
            slotProps={{
              textField: {
                helperText: t('resources.company.form.add_company.date_picker_helper_text'),
              },
            }}
            // eslint-disable-next-line
            // @ts-expect-error
            renderInput={params => <TextField {...params} onKeyDown={handleKeyDown} />}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
          <Box>
            {selectedDates.map((date, index) => {
              const day = `${String(date.getDate()).padStart(2, '0')}`;
              const month = `${String(date.getMonth() + 1).padStart(2, '0')}`;
              const year = `${date.getFullYear()}`;
              const fullDate = `${day}.${month}.${year}`;

              return (
                <Chip
                  key={index}
                  label={fullDate}
                  onDelete={() => handleRemovePayDay(date)}
                  style={{ margin: '5px' }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 10 }}>
        <Box>
          <Button onClick={handlePreviousStep} variant="outlined">
            {t('resources.company.form.add_company.previous_step')}
          </Button>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ margin: '0 10px' }}>
            <Button onClick={handleResetForm} variant="outlined">
              {t('resources.company.form.add_company.cancel_form')}
            </Button>
          </Box>
          <Box>
            <Button type="submit" variant="contained" disabled={isSubmitBtnDisabled}>
              {t('resources.company.form.add_company.save')}
            </Button>
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}

export default CompanySettingsTab;
