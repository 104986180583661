import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

function DashboardEmpty(): JSX.Element {
  const t = useTranslate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '70vh', width: '70vw' }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h6">
          {t('resources.dashboard.dashboard_empty')}
        </Typography>
      </Box>
    </Box>
  );
}

export default DashboardEmpty;
