export function getLastDayOfPreviousMonth(dateStr: string): Date {
  const now = new Date();
  const date = new Date(dateStr);

  date.setDate(1);
  date.setDate(date.getDate() - 1);
  date.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

  return date;
}
