import { AUTH_PATHS } from '@/api/apiPaths/authPaths';
import { ADMIN_PATHS, COMPANY_PATHS } from '@/api/apiPaths/companyPaths';
import { PSP_PATHS } from '@/api/apiPaths/pspPaths';
import { DASHBOARD_PATHS } from '@/api/apiPaths/dashboardPaths';
import { EMPLOYEES_PATHS } from '@/api/apiPaths/employeesPaths';
import { FILTERS_PATHS } from '@/api/apiPaths/filtersPaths';
import { TRANSACTIONS_PATHS } from '@/api/apiPaths/transactionsPaths';
import { IApiPaths } from '@/types';

export const API_PATHS: IApiPaths = {
  AUTH: AUTH_PATHS,
  COMPANY: COMPANY_PATHS,
  PSP: PSP_PATHS,
  EMPLOYEES: EMPLOYEES_PATHS,
  FILTERS: FILTERS_PATHS,
  ADMINS: ADMIN_PATHS,
  TRANSACTIONS: TRANSACTIONS_PATHS,
  DASHBOARD: DASHBOARD_PATHS,
} as const;
