import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

import { useGetUserRole } from '@/hooks';
import { RegistrationTypeEnum } from '@/types';
import { AddCompanyButton } from '@/view/companies/CompaniesList/components';

function CompaniesEmpty(): JSX.Element {
  const t = useTranslate();
  const role = useGetUserRole();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '70vh', width: '80vw' }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h6">
          {role && role === RegistrationTypeEnum.SUPER_ADMIN
            ? t('resources.company.company_empty_super_admin')
            : t('resources.company.company_empty_admin')}
        </Typography>
        {role && role === RegistrationTypeEnum.SUPER_ADMIN ? <AddCompanyButton /> : null}
      </Box>
    </Box>
  );
}

export default CompaniesEmpty;
