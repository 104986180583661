import { API_HANDLERS } from '@/api';
import { IPsp } from '@/types';

export const getList = async (): Promise<IPsp[]> => {
  try {
    const { data: responseData } = await API_HANDLERS.PSP.GET_LIST();

    return responseData;
  } catch (error) {
    return Promise.reject(new Error(`Get PSP List Error: ${error}`));
  }
};
