import { API_HANDLERS } from '@/api';
import { IEmployeesCheckUploadXLSResponseParams } from '@/types';

export const checkUploadEmployees = async (
  messageId: string,
): Promise<IEmployeesCheckUploadXLSResponseParams> => {
  try {
    const { data } = await API_HANDLERS.EMPLOYEES.UPLOAD_EMPLOYEES_STATUS(messageId);

    return data;
  } catch (error) {
    return Promise.reject(new Error(`Check Upload Employees Error: ${error}`));
  }
};
