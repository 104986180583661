import { IEmployeesPaths } from '@/types';

export const EMPLOYEES_PATHS: IEmployeesPaths = {
  LIST: {
    _: 'employees/list/',
  },
  UPLOAD_EMPLOYEES: {
    BY_ID: companyId => `employees/${companyId}/upload/`,
  },
  UPLOAD_EMPLOYEES_STATUS: {
    _: 'employees/upload-status/',
  },
  TERMINATE: {
    _: 'employees/terminate/',
  },
};
