import { FormControl, InputLabel, Select, MenuItem, type SelectChangeEvent } from '@mui/material';
import { type Dispatch, type SetStateAction } from 'react';
import { useTranslate } from 'react-admin';

import {
  generateYearOptions,
  type IYearOption,
} from '@/view/dashboard/Dashboard/components/ChartYearSelect/utils';

interface IChartYearSelectProps {
  setSelectedYear: Dispatch<SetStateAction<string>>;
}

export function ChartYearSelect({ setSelectedYear }: IChartYearSelectProps): JSX.Element {
  const t = useTranslate();

  const yearOptions: IYearOption[] = generateYearOptions();

  const handleYearChange = (event: SelectChangeEvent): void => {
    setSelectedYear(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="dashboard-year-chart-select-label">
        {t('resources.dashboard.choose_year')}
      </InputLabel>
      <Select
        defaultValue={yearOptions.length ? yearOptions[yearOptions.length - 1].id : undefined}
        labelId="dashboard-year-chart-select-label"
        id="dashboard-year-chart-select"
        onChange={handleYearChange}
      >
        {yearOptions.map(({ name, id }) => {
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ChartYearSelect;
