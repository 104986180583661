import { useState, useEffect, useRef } from 'react';
import { SIGN_WIDGET_PARENT_ID, SIGN_WIDGET_ID, SIGN_WIDGET_URI } from '@/constants';
import { IEndUser } from '@/types';

interface IProps {
  height?: number;
  onKeyReceived: (euSign: Window['EndUser']) => void;
}

function EuSignDialog({ height = 800, onKeyReceived }: IProps): JSX.Element {
  const containerRef = useRef(null);

  const [euSign, setEuSign] = useState<IEndUser>();
  const [isKeyReceived, setIsKeyReceived] = useState<boolean>(false);

  useEffect(() => {
    if (containerRef.current === null) {
      return;
    }

    const EndUser = window.EndUser;

    const instance = new EndUser(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      EndUser.FormType.ReadPKey,
    );

    instance.iframe.addEventListener('load', () => {
      setEuSign(instance);
    });
  }, [containerRef]);

  useEffect(() => {
    if (!euSign) {
      return;
    }

    euSign
      .ReadPrivateKey()
      .then(() => {
        setIsKeyReceived(true);
        onKeyReceived(euSign);
      })
      .catch((error: Error) => {
        alert(
          'Виникла помилка при зчитуванні ос. ключа. Опис помилки: ' + (error.message || error),
        );
      });
  }, [euSign]);

  return (
    <>
      <div
        style={{ height, display: !isKeyReceived ? 'block' : 'none' }}
        id={SIGN_WIDGET_PARENT_ID}
        ref={containerRef}
      ></div>
    </>
  );
}

export default EuSignDialog;
