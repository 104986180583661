import {
  FilterButton,
  // SelectColumnsButton,
  TopToolbar,
} from 'react-admin';

import { ImportCSVButton } from '@/view/employees/EmployeesList/components/ImportCSVButton';

export interface IListActionsProps {
  handleOpenImportCSVModal: () => void;
}

function ListActions({ handleOpenImportCSVModal }: IListActionsProps): JSX.Element {
  return (
    <TopToolbar>
      <FilterButton />
      {/*<SelectColumnsButton />*/}
      <ImportCSVButton handleOpenImportCSVModal={handleOpenImportCSVModal} />
    </TopToolbar>
  );
}

export default ListActions;
