import englishDateLocale from 'date-fns/locale/en-US';
import ukrainianDateLocale from 'date-fns/locale/uk';
import { type I18nProvider } from 'ra-core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { type TranslationMessages } from 'react-admin';

import englishMessages from '@/i18n/dictionaries/en';
// import frenchMessages from '@/i18n/dictionaries/fr';
import ukrainianMessages from '@/i18n/dictionaries/ua';

type TAvailableLocales = 'ua' | 'en';
type TDateLocale = Record<string, unknown>;
type TLocaleMessages = {
  [key in TAvailableLocales]: {
    messages: TranslationMessages;
    dateLocale: TDateLocale;
  };
};

const messages: TLocaleMessages = {
  en: {
    messages: englishMessages,
    dateLocale: englishDateLocale,
  },
  ua: {
    messages: ukrainianMessages,
    dateLocale: ukrainianDateLocale,
  },
};

export function getTranslateProvider(): I18nProvider {
  return polyglotI18nProvider(
    (locale: string) => {
      const key = locale as TAvailableLocales; // Asserting that locale is a valid key
      return messages[key]?.messages || ukrainianMessages;
    },
    'ua', // Default locale
    [
      { locale: 'ua', name: 'Українська' },
      { locale: 'en', name: 'English' },
    ],
  );
}

// old solution without date-fns
// export function getTranslateProvider(): I18nProvider {
//   return polyglotI18nProvider(
//     locale => {
//       if (locale === 'ua') {
//         return ukrainianMessages;
//       }
//
//       if (locale === 'en') {
//         return englishMessages;
//       }
//
//       // if (locale === 'fr') {
//       //   return frenchMessages;
//       // }
//
//       // Always fallback on ukrainian
//       return ukrainianMessages;
//     },
//     'ua',
//     [
//       { locale: 'ua', name: 'Українська' },
//       { locale: 'en', name: 'English' },
//       // { locale: 'fr', name: 'Français' },
//     ],
//   );
// }
