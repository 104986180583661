import { API_HANDLERS } from '@/api';
import { IChangePasswordRequestParams } from '@/types';

interface IChangePasswordReqData {
  data: IChangePasswordRequestParams;
  onSuccess: () => void;
  onError: () => void;
}

export const changePassword = async ({
  data,
  onSuccess,
  onError,
}: IChangePasswordReqData): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.AUTH.CHANGE_PASSWORD(data);

    onSuccess();
    return response && true;
  } catch (error) {
    onError();
    return Promise.reject(new Error(`Change Password Error: ${error}`));
  }
};
