import { IAuthPaths } from '@/types';

export const AUTH_PATHS: IAuthPaths = {
  SIGN_IN: {
    _: `/auth/sign-in/`,
  },
  SIGN_OUT: {
    _: `/auth/sign-out/`,
  },
  REFRESH_TOKEN: {
    _: `/auth/refresh-token/`,
  },
  FORGOT_PASSWORD: {
    _: '/auth/forgot-password',
  },
  CHANGE_FORGOT_PASSWORD: {
    _: '/auth/change-forgot-password',
  },
  CHANGE_PASSWORD: {
    _: '/auth/change-password',
  },
} as const;
