import Box from '@mui/material/Box';
import { List, DatagridConfigurable, DateField, TextField, useTranslate } from 'react-admin';

import { UsersEmpty } from '@/view/users/UsersEmpty';

function UsersList(): JSX.Element {
  const t = useTranslate();

  return (
    <Box sx={{ marginTop: '24px' }}>
      <List
        perPage={25}
        empty={<UsersEmpty text={t('resources.admins.admins_empty')} />}
        exporter={false}
        actions={false}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="first_name" label="resources.employees.first_name" sortable={false} />
          <TextField source="last_name" label="resources.employees.last_name" sortable={false} />
          <TextField source="email" label="labels.email" sortable={false} />
          <TextField
            source="company.company_name"
            label="resources.company.company"
            sortable={false}
          />
          <DateField source="createdAt" label="resources.admins.created_date" sortable={false} />
        </DatagridConfigurable>
      </List>
    </Box>
  );
}

export default UsersList;
