import { type Translate } from 'react-admin';
import { z } from 'zod';

export function getCompanyFormValidationSchema(translate: Translate) {
  const stringToNumber = (value: unknown) => {
    if (typeof value === 'string' && value !== '') {
      const number = parseFloat(value);
      return !isNaN(number) ? number : value; // Повертаємо вихідне значення, якщо конвертація неможлива
    }
    return value;
  };

  const numberField = (field: string) =>
    z.preprocess(
      stringToNumber,
      z
        .number()
        .min(0, translate(`resources.company.form.add_company_errors.${field}_required`))
        .or(
          z.string().refine(val => !isNaN(parseFloat(val)), {
            message: translate('resources.company.form.add_company_errors.invalid_number'),
          }),
        ),
    );

  const adminSchema = z.object({
    first_name: z
      .string()
      .min(1, translate('resources.company.form.add_company_errors.admin_first_name_required')),
    last_name: z
      .string()
      .min(1, translate('resources.company.form.add_company_errors.admin_last_name_required')),
    email: z
      .string()
      .email(translate('resources.company.form.add_company_errors.invalid_email_address')),
  });

  const companyFormSchema = z.object({
    company_name: z
      .string()
      .min(1, translate('resources.company.form.add_company_errors.company_name_required')),
    legal_entity_id: z
      .string()
      .max(8, translate('resources.company.form.add_company_errors.legal_entity_id_max_value'))
      .min(8, translate('resources.company.form.add_company_errors.legal_entity_id_min_value'))
      .regex(
        /^\d+$/,
        translate('resources.company.form.add_company_errors.legal_entity_id_number'),
      ),
    legal_name: z
      .string()
      .min(1, translate('resources.company.form.add_company_errors.legal_name_required')),
    merchant_id: z.string().optional(),
    merchant_key: z.string().optional(),
    merchant_name: z.string().optional(),
    min_amount: numberField('min_amount'),
    number_of_transactions: numberField('number_of_transactions'),
    withdrawal_limit: z.preprocess(
      stringToNumber,
      z
        .number()
        .max(100, translate('resources.company.form.add_company_errors.withdrawal_limit_exceeded'))
        .min(0, translate(`resources.company.form.add_company_errors.withdrawal_limit_required`))
        .or(
          z.string().refine(val => !isNaN(parseFloat(val)), {
            message: translate('resources.company.form.add_company_errors.invalid_number'),
          }),
        ),
    ),
    withdrawal_method: z
      .string()
      .min(1, translate('resources.company.form.add_company_errors.withdrawal_method_required')),
    admins: z
      .array(adminSchema)
      .nonempty(translate('resources.company.form.add_company_errors.admins_required'))
      .min(1),
    pspId: z
      .string({
        invalid_type_error: translate('resources.company.form.add_company_errors.psp_required'),
      })
      .uuid(),
  });

  return companyFormSchema;
}

export type TCompanyFormValidationSchemaReturnType = ReturnType<
  typeof getCompanyFormValidationSchema
>;
export type TCompanyFormData = z.infer<ReturnType<typeof getCompanyFormValidationSchema>>;
