import { Identifier } from 'ra-core';

import { API_HANDLERS } from '@/api';
import { ICompanyCredentialsItemResponseParams } from '@/types';

export const getCredentialsList = async (
  companyId: Identifier,
): Promise<ICompanyCredentialsItemResponseParams[]> => {
  try {
    const { data: responseData } = await API_HANDLERS.COMPANY.GET_CREDENTIALS_LIST(companyId);

    return responseData;
  } catch (error) {
    return Promise.reject(new Error(`Get Company Credentials List Error: ${error}`));
  }
};
