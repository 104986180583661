import { AuthProvider } from 'react-admin';

import { AuthService } from '@/api';
import UserPermissionsService from '@/api/userPermissionsService';

const authProvider: AuthProvider = {
  login: AuthService.signIn,
  logout: AuthService.signOut,
  checkError: AuthService.checkError,
  checkAuth: AuthService.checkAuth,
  getPermissions: () => Promise.resolve(UserPermissionsService.rbacData),
  getIdentity: AuthService.getIdentity,
};

export default authProvider;
