export const DATA_PROVIDER_RESOURCES = {
  COMPANY: {
    _: 'companies',
  },
  ADMINS: {
    _: 'users',
  },
  EMPLOYEES: {
    _: 'employees',
  },
  EMPLOYEES_FILTERS: {
    STATUS: {
      _: 'employees_status',
    },
  },
  TRANSACTIONS: {
    _: 'transactions',
    EXPORT: {
      _: 'transactions_csv',
    },
  },
  DASHBOARD: {
    _: 'dashboard',
  },
} as const;
