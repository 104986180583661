import { Dispatch, SetStateAction } from 'react';

import { TActiveTab } from '@/view/companies/CompaniesEdit/components/form/EditCompanyForm/EditCompanyForm';

export function showErrorNotification(
  message: string,
  // eslint-disable-next-line
  notify: any,
  setActiveTab: Dispatch<SetStateAction<TActiveTab>>,
): void {
  if (message === 'company.errors.secret_manager_key_exist') {
    setActiveTab('company_info');
    notify('resources.company.form.add_company_errors.secret_manager_key_exist', {
      type: 'error',
    });
  } else if (message === 'company.errors.merchant_empty') {
    setActiveTab('company_info');
    notify('resources.company.form.add_company_errors.merchant_empty', {
      type: 'error',
    });
  } else if (message === 'company.errors.invalid_merchant_id_or_key') {
    setActiveTab('company_info');
    notify('resources.company.form.add_company_errors.invalid_merchant_id_or_key', {
      type: 'error',
    });
  } else if (message === 'company.errors.incorrect_salt_or_sign') {
    notify('resources.company.form.add_company_errors.invalid_name', {
      type: 'error',
    });
  } else if (message.includes('Invalid name. Must be a valid name')) {
    notify('resources.company.form.add_company_errors.invalid_name', {
      type: 'error',
    });
  } else if (message.includes('The operation failed because the secret')) {
    notify('resources.company.form.add_company_errors.secret_merchant', {
      type: 'error',
    });
  } else if (message === 'company.errors.nullable_merchant_fields') {
    notify('resources.company.form.add_company_errors.nullable_merchant_fields', {
      type: 'error',
    });
  } else if (message === 'company.errors.merchant_name_contains_space') {
    notify('resources.company.form.add_company_errors.merchant_name_contains_space', {
      type: 'error',
    });
  } else {
    notify(message, {
      type: 'error',
    });
  }
}
