import {
  Divider,
  // Theme,
  // useMediaQuery,
} from '@mui/material';
import { DatagridConfigurable, DateField, TextField, ShowButton, EditButton } from 'react-admin';

import { useGetUserRole } from '@/hooks';
import { RegistrationTypeEnum } from '@/types';
import { CompaniesEmpty } from '@/view/companies/CompaniesEmpty';
// import { MobileGrid } from '@/view/companies/CompaniesList/components/MobileGrid';

function TabbedDatagrid(): JSX.Element {
  // const isXSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const roles = useGetUserRole();

  return (
    <>
      <Divider />
      <DatagridConfigurable rowClick="show" bulkActionButtons={false} empty={<CompaniesEmpty />}>
        <TextField source="company_name" label="resources.company.company" sortable={false} />
        <TextField source="status" label="resources.company.status" sortable={false} />
        <DateField
          source="last_update"
          label="resources.company.last_update"
          showTime
          sortable={false}
        />
        <ShowButton
          disabled={
            !(
              roles === RegistrationTypeEnum.SUPER_ADMIN ||
              roles === RegistrationTypeEnum.COMPANY_ADMIN
            )
          }
        />
        {roles !== RegistrationTypeEnum.COMPANY_ADMIN ? <EditButton /> : null}
      </DatagridConfigurable>
    </>
  );
}

export default TabbedDatagrid;
