import { API_HANDLERS } from '@/api';
import { TFiltersRequestParams } from '@/types';

export const getFilters = async <ReturnType>(type: TFiltersRequestParams): Promise<ReturnType> => {
  try {
    const { data } = await API_HANDLERS.FILTERS.GET_FILTERS(type);

    const statusFilters = Object.values(data.values).map(value => ({
      id: value,
      name: value,
    }));

    return statusFilters as ReturnType;
  } catch (error) {
    return Promise.reject(new Error(`Get ${type} Filters Error: ${error}`));
  }
};
