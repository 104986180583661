import {
  FilterButton,
  // SelectColumnsButton,
  TopToolbar,
} from 'react-admin';

import { SignWithdrawalOrdersButton } from '@/view/transactions/TransactionsList/components';
import { ExportTransactionsButton } from '@/view/transactions/TransactionsList/components';

interface IProps {
  openSignOrdersDialog: () => void;
}

function ListActions({ openSignOrdersDialog }: IProps): JSX.Element {
  return (
    <TopToolbar>
      <SignWithdrawalOrdersButton onClick={openSignOrdersDialog} />
      <FilterButton />
      <ExportTransactionsButton />
    </TopToolbar>
  );
}

export default ListActions;
