import { format } from 'date-fns';

import { IDashboardGraphItem } from '@/types';

interface IGraphByDay {
  [key: string]: {
    total: number;
    amount: number;
  };
}

export function aggregateGraphByDay(graph: IDashboardGraphItem[]): IGraphByDay {
  return graph.reduce((acc, curr) => {
    const day = format(new Date(curr.date), 'yyyy-MM-dd');
    if (!acc[day]) {
      acc[day] = { total: 0, amount: 0 };
    }
    acc[day].total += curr.total;
    acc[day].amount += parseFloat(curr.amount.replace(/[^0-9.-]+/g, ''));
    return acc;
  }, {} as IGraphByDay);
}
