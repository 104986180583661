import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';

function Loader(): JSX.Element {
  return (
    <Stack spacing={1} direction="row">
      <CircularProgress color="primary" />
    </Stack>
  );
}

export default Loader;
