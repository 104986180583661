import { Layout, LayoutProps } from 'react-admin';

import { AppBar } from '@/view/layout/AppBar';
import { Menu } from '@/view/layout/Menu';

function CustomLayout(props: LayoutProps): JSX.Element {
  return <Layout {...props} appBar={AppBar} menu={Menu} />;
}

export default CustomLayout;
