import { API_HANDLERS } from '@/api';
import { ICompanyItemRequestParams, ICompanyItemResponseParams } from '@/types';

export const getItem = async (
  requestParams: ICompanyItemRequestParams,
): Promise<{ data: ICompanyItemResponseParams }> => {
  try {
    const { data } = await API_HANDLERS.COMPANY.GET_ITEM_BY_ID(requestParams);

    return Promise.resolve({ data });
  } catch (error) {
    return Promise.reject(new Error(`Get Employees List Error: ${error}`));
  }
};
