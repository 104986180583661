import { API_HANDLERS } from '@/api';
import { ICompaniesRequestParams } from '@/types';

export const getAdminList = async (data: any) => {
  try {
    const requestData: ICompaniesRequestParams = {
      page: data?.pagination.page,
      order: data?.sort.order,
      take: data?.pagination.perPage,
    };

    const { data: responseData } = await API_HANDLERS.ADMINS.GET_LIST(requestData);

    return Promise.resolve({
      data: responseData.data,
      total: responseData.meta.itemCount,
      pageInfo: {
        hasNextPage: responseData.meta.hasNextPage,
        hasPreviousPage: responseData.meta.hasPreviousPage,
      },
    });
  } catch (error) {
    return Promise.reject(new Error(`Get company Error: ${error}`));
  }
};
