import { API_HANDLERS } from '@/api';
import { ISignTransactionWithdrawalOrderRequestParams } from '@/types';

export const signTransactionWithdrawalOrder = async (
  data: ISignTransactionWithdrawalOrderRequestParams,
  onSuccess?: () => void,
  // eslint-disable-next-line
  onError?: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.TRANSACTIONS.SIGN_WITHDRAWAL_ORDER(data);

    onSuccess?.();
    return response && true;
  } catch (error) {
    onError?.(error);
    return Promise.reject(new Error(`Sign withdrawal order error: ${error}`));
  }
};
