import { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';

import { filtersDataProvider } from '@/dataProvider';
import { IWithdrawalOrderStatus } from '@/types';

function WithdrawalOrderStatusAutocompleteInput(): JSX.Element {
  const [statuses, setStatuses] = useState<IWithdrawalOrderStatus[]>([]);

  const getFilters = async (): Promise<IWithdrawalOrderStatus[]> => {
    const statusFilters =
      await filtersDataProvider.getFilters<IWithdrawalOrderStatus[]>('WithdrawalOrder');
    setStatuses(statusFilters);

    return statusFilters;
  };

  useEffect(() => {
    void getFilters();
  }, []);

  return (
    <AutocompleteInput
      label="resources.transactions.withdrawal_order_status"
      source="withdrawalOrderStatus"
      optionValue="id"
      choices={statuses}
      sx={{ minWidth: 300 }}
    />
  );
}

export default WithdrawalOrderStatusAutocompleteInput;
