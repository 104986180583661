export function getDaysInMonth(dateString: string): number {
  const [year, month] = dateString.split('-').map(Number);

  const nextMonth = new Date(year, month, 0);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  if (year === currentYear && month === currentMonth) {
    return currentDate.getDate();
  } else {
    return nextMonth.getDate();
  }
}
