import { ITransactionsPaths } from '@/types';

export const TRANSACTIONS_PATHS: ITransactionsPaths = {
  LIST: {
    _: 'transactions/list/',
  },
  EXPORT: {
    _: 'transactions/export/',
  },
  SIGN_WITHDRAWAL_ORDER: {
    _: 'transactions/withdrawal-orders/',
  },
};
