import { type Translate } from 'react-admin';
import { z } from 'zod';

export function getChangePasswordFormValidationSchema(translate: Translate) {
  const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

  const zodSchema = z
    .object({
      oldPassword: z
        .string()
        .min(1, translate('resources.settings.change_password_form.required_field')),
      password: z
        .string()
        .min(1, translate('resources.settings.change_password_form.required_field'))
        .regex(
          passwordRegex,
          translate('resources.settings.change_password_form.errors.password_rule'),
        ),
      confirmPassword: z
        .string()
        .min(1, translate('resources.settings.change_password_form.required_field')),
    })
    .refine(data => data.password === data.confirmPassword, {
      message: translate('resources.settings.change_password_form.errors.password_doesnt_match'),
      path: ['confirmPassword'],
    });

  return zodSchema;
}
