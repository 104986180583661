import { FileDownload } from '@mui/icons-material';
import { Box } from '@mui/material';
import { GetListParams } from 'ra-core';
import { useListContext, Button } from 'react-admin';

import { transactionsDataProvider } from '@/dataProvider';

function ExportTransactionsButton(): JSX.Element {
  const { filterValues, perPage, page, sort } = useListContext();

  const getXLS = async (data: GetListParams): Promise<void> => {
    const response = await transactionsDataProvider.exportTransactions(data);

    if (response) {
      const url = response;
      const link = document.createElement('a');
      link.href = url;
      // link.setAttribute('download', 'transactions.xls');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  };

  const handleClick = (): void => {
    void getXLS({
      filter: {
        status: filterValues?.status,
        endDate: filterValues?.endDate,
        startDate: filterValues?.startDate,
        full_name: filterValues?.full_name,
        companies: filterValues?.companies,
      },
      pagination: {
        page,
        perPage,
      },
      sort,
    });
  };

  return (
    <Box>
      <Button
        startIcon={<FileDownload />}
        label="resources.transactions.export_xls"
        onClick={handleClick}
      />
    </Box>
  );
}

export default ExportTransactionsButton;
