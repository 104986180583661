import Box from '@mui/material/Box';
import { useState } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from 'react-admin';

import { useGetUserRole } from '@/hooks';
import { RegistrationTypeEnum } from '@/types';
import companies from '@/view/companies';
import employees from '@/view/employees';
import { SubMenu } from '@/view/layout';
import settings from '@/view/settings';
import transactions from '@/view/transactions';
import users from '@/view/users';

type TMenuName = 'menuSettings';

function Menu({ dense = false }: MenuProps) {
  const translate = useTranslate();
  const [open] = useSidebarState();

  const role = useGetUserRole();

  const [state, setState] = useState({
    menuSettings: true,
  });

  const handleToggle = (menu: TMenuName) => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: theme =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <MenuItemLink
        to="/companies"
        state={{ _scrollToTop: true }}
        primaryText={
          role === RegistrationTypeEnum.SUPER_ADMIN
            ? translate(`pos.menu.companies`, {
                smart_count: 2,
              })
            : translate(`pos.menu.company`, {
                smart_count: 2,
              })
        }
        leftIcon={<companies.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/employees"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.employees`, {
          smart_count: 2,
        })}
        leftIcon={<employees.icon />}
        dense={dense}
      />

      {/* eslint-disable-next-line no-restricted-syntax */}
      {(role ?? '') === RegistrationTypeEnum.SUPER_ADMIN && (
        <MenuItemLink
          to="/users"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.users`, {
            smart_count: 2,
          })}
          leftIcon={<users.icon />}
          dense={dense}
        />
      )}
      <MenuItemLink
        to="/transactions"
        state={{ _scrollToTop: true }}
        primaryText={translate(`pos.menu.transactions`, {
          smart_count: 2,
        })}
        leftIcon={<transactions.icon />}
        dense={dense}
      />
      <SubMenu
        dense={dense}
        handleToggle={() => handleToggle('menuSettings')}
        icon={<settings.subIcon />}
        isOpen={state.menuSettings}
        name="pos.menu.settings"
      >
        <MenuItemLink
          to="/change-password"
          state={{ _scrollToTop: true }}
          primaryText={translate(`pos.menu.change_password`, {
            smart_count: 2,
          })}
          leftIcon={<settings.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
}

export default Menu;
