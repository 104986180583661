import { KeyOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Button } from 'react-admin';

interface IProps {
  onClick: () => void;
}

function SignWithdrawalOrdersButton({ onClick }: IProps): JSX.Element {
  return (
    <Box>
      <Button
        startIcon={<KeyOutlined />}
        label="resources.transactions.sign_withdrawal_orders"
        onClick={onClick}
      />
    </Box>
  );
}

export default SignWithdrawalOrdersButton;
