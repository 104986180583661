import { API_HANDLERS } from '@/api';

interface IForgotPasswordRequestData {
  email: string;
  onSuccess: () => void;
  // eslint-disable-next-line
  onError: (error: any) => void;
}

export const forgotPassword = async ({
  email,
  onError,
  onSuccess,
}: IForgotPasswordRequestData): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.AUTH.FORGOT_PASSWORD(email);

    onSuccess();
    return !!response && !!response.data.message && true;
  } catch (error) {
    onError(error);
    return Promise.reject(new Error(`Forgot Password Error: ${error}`));
  }
};
