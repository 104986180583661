import { Box } from '@mui/material';
import { CreateButton } from 'react-admin';

function AddCompanyButton(): JSX.Element {
  return (
    <Box>
      <CreateButton
        label="resources.company.add_company_btn"
        sx={{ margin: 0, padding: '4px 5px', lineHeight: 1.5 }}
        resource="companies"
      />
    </Box>
  );
}

export default AddCompanyButton;
