export interface IObserverFunction<Data> {
  (data: Data): void;
}

export interface IObserver<Data> extends IObserverFunction<Data> {
  unsubscribe: () => void;
}

class Subject<Data> {
  observers = new Set<IObserver<Data>>();

  subscribe(observerFn: IObserverFunction<Data>): IObserver<Data> {
    const observer = observerFn as IObserver<Data>;
    observer.unsubscribe = this.unsubscribe.bind(this, observer);
    this.observers.add(observer);
    return observer;
  }

  unsubscribe(observer: IObserver<Data>): void {
    this.observers.delete(observer);
  }

  dispatch = (data: Data): void => {
    const observers = [...this.observers];
    observers.forEach((observer) => {
      observer(data);
    });
  };
}

export default Subject;
