import { Card, CardHeader, CardContent } from '@mui/material';
import { addDays } from 'date-fns';
import { useTranslate } from 'react-admin';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import { IDashboardGraphItem } from '@/types';
import { getLastDayOfPreviousMonth, getLastDayOfMonth } from '@/utils';
import { CustomChartTooltip } from '@/view/dashboard/Dashboard/components';
import {
  dateFormatter,
  getRevenuePerDay,
} from '@/view/dashboard/Dashboard/components/OperationsChart/utils';

interface IOperationsChartProps {
  endDate: string;
  graph?: IDashboardGraphItem[];
}

function OperationsChart({ endDate, graph }: IOperationsChartProps): JSX.Element | null {
  const translate = useTranslate();

  if (!graph) return null;

  return (
    <Card>
      <CardHeader title={translate('resources.dashboard.month_history')} />
      <CardContent>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getRevenuePerDay(endDate, graph)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[
                  addDays(getLastDayOfPreviousMonth(endDate), 1).getTime(),
                  getLastDayOfMonth(endDate).getTime(),
                ]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Amount" unit="₴" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={CustomChartTooltip} />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
}

export default OperationsChart;
