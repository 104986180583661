import { API_HANDLERS } from '@/api';
import { IEmployeesUploadXLSRequestParams } from '@/types';

export const uploadEmployees = async (
  requestData: IEmployeesUploadXLSRequestParams,
): Promise<string> => {
  try {
    const { data } = await API_HANDLERS.EMPLOYEES.UPLOAD_EMPLOYEES(requestData);

    return data.MessageId;
  } catch (error) {
    return Promise.reject(new Error(`Upload Employees Error: ${error}`));
  }
};
