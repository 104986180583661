import { useEffect, useState } from 'react';

import { AuthService } from '@/api';

export const useGetUserCompanyId = (): string | null => {
  const [userCompanyId, setUserCompanyId] = useState<string | null>(null);

  useEffect(() => {
    const handleCompanyId = async (): Promise<void> => {
      const companyId = await AuthService.getUserCompanyId();
      setUserCompanyId(companyId);
    };

    handleCompanyId();
  });

  return userCompanyId;
};

export default useGetUserCompanyId;
