import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslate, useNotify, useRedirect } from 'react-admin';
import { useForm, Controller } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import loginBackgroundImage from '@/assets/img/loginBackgroundImage.webp';
import FlexipayIcon from '@/assets/svg/flexipay-logo-icon.svg';
import { authDataProvider } from '@/dataProvider/authDataProvider';
import { IChangeForgotPasswordRequestParams } from '@/types';
import { parseQuery } from '@/utils';
import { getChangeForgotPasswordValidationSchema } from '@/view/layout/ChangeForgotPassword/validation';

interface IFormData {
  newPassword: string;
  confirmNewPassword: string;
}

type TConfirmedPassword = 'confirmed' | 'unconfirmed' | '';

function ChangeForgotPassword(): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const { search } = useLocation();

  const [confirmedPassword, setConfirmedPassword] = useState<TConfirmedPassword>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState<boolean>(false);

  const zodSchema = getChangeForgotPasswordValidationSchema(t);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IFormData>({
    resolver: zodResolver(zodSchema),
    mode: 'onChange',
  });

  const onSuccess = (): void => {
    setConfirmedPassword('confirmed');
  };

  // eslint-disable-next-line
  const onError = (error: any): void => {
    if (error.response.data.message) {
      notify(t(error.response.data.message), {
        type: 'error',
      });
    } else {
      setConfirmedPassword('unconfirmed');
    }
  };

  const changePassword = async (data: IChangeForgotPasswordRequestParams): Promise<boolean> => {
    const response = await authDataProvider.changeForgotPassword({ data, onSuccess, onError });

    return response;
  };

  const onSubmit = ({ newPassword, confirmNewPassword }: IFormData): void => {
    const { email, token } = parseQuery(search);
    void changePassword({
      email,
      token,
      password: newPassword,
      confirmPassword: confirmNewPassword,
    });
  };

  const newPasswordValue = watch('newPassword');

  useEffect(() => {
    if (confirmedPassword === 'confirmed') {
      notify(t('forgot_password.errors.valid_password'), {
        type: 'success',
      });
      setConfirmedPassword('');
      redirect('/login');
    } else if (confirmedPassword === 'unconfirmed') {
      notify(t('forgot_password.errors.invalid_password'), {
        type: 'error',
      });
      setConfirmedPassword('');
    }
  }, [confirmedPassword]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `url(${loginBackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ width: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.light' }}>
              <img src={FlexipayIcon} alt="FlexipayIcon" width={40} height={40} />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: theme => theme.palette.grey[500],
            }}
          >
            <strong>FlexiPay</strong>
          </Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box sx={{ marginTop: '1em' }}>
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    type={showNewPassword ? 'text' : 'password'}
                    label={t('forgot_password.new_password')}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword?.message}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            edge="end"
                          >
                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <Controller
                name="confirmNewPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    type={showConfirmNewPassword ? 'text' : 'password'}
                    label={t('forgot_password.confirm_new_password')}
                    error={
                      !!errors.confirmNewPassword ||
                      (!!newPasswordValue && field.value !== newPasswordValue)
                    }
                    helperText={
                      errors.confirmNewPassword?.message ||
                      (newPasswordValue && field.value !== newPasswordValue
                        ? t('forgot_password.errors.password_doesnt_match')
                        : '')
                    }
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                            edge="end"
                          >
                            {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: '0 1em 1em 1em', justifyContent: 'center' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !!errors.confirmNewPassword ||
                !newPasswordValue ||
                watch('confirmNewPassword') !== newPasswordValue
              }
            >
              {t('forgot_password.change_password_btn')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </form>
  );
}

export default ChangeForgotPassword;
