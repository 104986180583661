import { API_HANDLERS } from '@/api';
import { ICompaniesFilter } from '@/types';

export const getCompanies = async (): Promise<ICompaniesFilter[]> => {
  try {
    const { data } = await API_HANDLERS.FILTERS.GET_COMPANY();

    return data.companies;
  } catch (error) {
    return Promise.reject(new Error(`Get Companies Filters Error: ${error}`));
  }
};
