import { type Identifier } from 'react-admin';

import { API_HANDLERS } from '@/api';
import { ICompanyItemResponseParams } from '@/types';

export const editCompany = async (
  companyId: Identifier,
  data: ICompanyItemResponseParams,
  onSuccess: () => void,
  // eslint-disable-next-line
  onError: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.COMPANY.EDIT_COMPANY_ITEM(companyId, data);

    onSuccess();
    return response && true;
  } catch (error) {
    onError(error);
    return Promise.reject(new Error(`Create Company Error: ${error}`));
  }
};
