import { useEffect, useState } from 'react';

import { AuthService } from '@/api';
import { RegistrationTypeEnum } from '@/types';

const useGetUserRole = (): RegistrationTypeEnum | null => {
  const [userRole, setUserRole] = useState<RegistrationTypeEnum | null>(null);

  useEffect(() => {
    const handleRole = async () => {
      const role = await AuthService.getUserRole();
      setUserRole(role);
    };

    handleRole();
  });

  return userRole;
};

export default useGetUserRole;
