import { useEffect, useRef } from 'react';

const usePolling = (callback: () => void, interval: number, isActive: boolean) => {
  const savedCallback = useRef<() => void>();

  const tick = (): void => {
    if (savedCallback.current) {
      savedCallback.current();
    }
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isActive && interval !== null) {
      const id = setInterval(tick, interval);
      return () => clearInterval(id);
    }
  }, [interval, isActive]);
};

export default usePolling;
