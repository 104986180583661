import StopCircleIcon from '@mui/icons-material/StopCircle';
import {
  Button,
  useUpdateMany,
  useNotify,
  useUnselectAll,
  Identifier,
  useListContext,
} from 'react-admin';

const noSelection: Identifier[] = [];

function BulkTerminateButton() {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('employees');

  const [updateMany, { isLoading }] = useUpdateMany(
    'employees',
    { ids: selectedIds, data: { status: 'Очікується призупинення', is_terminated: true } },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resources.reviews.notification.approved_success', {
          type: 'info',
          undoable: true,
        });
        unselectAll();
      },
      onError: () => {
        notify('resources.reviews.notification.approved_error', {
          type: 'error',
        });
      },
    },
  );

  return (
    <Button
      sx={{
        color: '#d32f2f',
      }}
      label="resources.employees.terminate"
      onClick={() => updateMany()}
      disabled={isLoading}
    >
      <StopCircleIcon />
    </Button>
  );
}

export default BulkTerminateButton;
