import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, IconButton } from '@mui/material';
import { useRecordContext } from 'react-admin';

import { ITransactionsResponseData } from '@/types';

function WithdrawalOrderPDF(): JSX.Element {
  const { withdrawal_order_link, signed_order_link } =
    useRecordContext<ITransactionsResponseData>();

  const handleOpenPdf = (url: string): void => {
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ marginRight: '30px' }}>
      {!signed_order_link && (
        <IconButton
          color="default"
          onClick={() => withdrawal_order_link && handleOpenPdf(withdrawal_order_link)}
          disabled={!withdrawal_order_link}
        >
          <PictureAsPdfIcon />
        </IconButton>
      )}
      {signed_order_link && (
        <IconButton color="error" onClick={() => handleOpenPdf(signed_order_link)}>
          <PictureAsPdfIcon />
        </IconButton>
      )}
    </Box>
  );
}

export default WithdrawalOrderPDF;
