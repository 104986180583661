import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

interface IEmployeesEmptyProps {
  text?: string;
}

function UsersEmpty({ text }: IEmployeesEmptyProps): JSX.Element {
  const t = useTranslate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '70vh', width: '80vw' }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Typography variant="h6" component="h6">
          {/* eslint-disable-next-line no-restricted-syntax */}
          {text || t('resources.employees.employees_empty')}
        </Typography>
      </Box>
    </Box>
  );
}

export default UsersEmpty;
