import { ICompanyPaths } from '@/types';

export const COMPANY_PATHS: ICompanyPaths = {
  CREATE: {
    _: `/company/create/`,
  },
  CREATE_CREDENTIALS: {
    BY_ID: (companyId, pspUniqueName) => `/company/${companyId}/psp/${pspUniqueName}/credentials`,
  },
  UPDATE_CREDENTIALS: {
    BY_ID: (companyId, pspUniqueName, credentialId) =>
      `/company/${companyId}/psp/${pspUniqueName}/credentials/${credentialId}`,
  },
  UPLOAD_LOGO: {
    _: `/company/upload-logo/`,
  },
  UPLOAD_CONTRACT: {
    _: '/company/upload-contract/',
  },
  LIST: {
    _: `/company/list/`,
  },
  ITEM: {
    BY_ID: companyId => `/company/${companyId}/`,
  },
  EDIT_COMPANY_ITEM: {
    BY_ID: companyId => `/company/${companyId}/edit/`,
  },
  GET_COMPANY: {
    _: `/company/`,
  },
  CREDENTIALS_LIST: {
    BY_ID: companyId => `/company/${companyId}/credentials`,
  },
} as const;

export const ADMIN_PATHS = {
  LIST: {
    _: `/admins/list/`,
  },
};
