import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { EmployeesEdit } from '@/view/employees/EmployeesEdit';
import { EmployeesList } from '@/view/employees/EmployeesList';

export default {
  list: EmployeesList,
  edit: EmployeesEdit,
  icon: PeopleAltIcon,
  recordRepresentation: 'reference',
};
