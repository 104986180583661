import { USER_PERMISSIONS } from '@/api/constants';

const UngroupedUserPermissions = Object.values(USER_PERMISSIONS).flat();

export type TPermissionsType = Partial<
  (typeof UngroupedUserPermissions)[keyof typeof UngroupedUserPermissions]
>;

export interface IPermissions {
  Companies: string[];
  Employees: string[];
  Transactions: string[];
}

export interface IRbac {
  action: string[];
  resource: string;
}
