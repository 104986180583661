import { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';

import { filtersDataProvider } from '@/dataProvider';
import { ICompaniesFilter, ICompaniesSelect } from '@/types';

function TransactionsCompaniesAutocompleteInput(): JSX.Element {
  const [companiesFilter, setCompaniesFilter] = useState<ICompaniesSelect[]>([]);

  const getCompaniesFilter = async (): Promise<ICompaniesFilter[]> => {
    const companies = await filtersDataProvider.getCompanies();
    const selectData = companies.map(item => ({ ...item, name: item.company_name }));
    setCompaniesFilter(selectData);

    return companies;
  };

  useEffect(() => {
    void getCompaniesFilter();
  }, []);

  return (
    <AutocompleteInput
      label="resources.transactions.companies"
      source="companies"
      optionValue="id"
      choices={companiesFilter}
      sx={{ minWidth: 300 }}
    />
  );
}

export default TransactionsCompaniesAutocompleteInput;
