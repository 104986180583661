import KeyIcon from '@mui/icons-material/Key';
import SettingsIcon from '@mui/icons-material/Settings';

import { ChangePassword } from '@/view/settings/ChangePassword';

export default {
  list: ChangePassword,
  icon: KeyIcon,
  subIcon: SettingsIcon,
  recordRepresentation: 'reference',
};
