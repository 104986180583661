import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  type SelectChangeEvent,
} from '@mui/material';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslate } from 'react-admin';

import { Loader } from '@/components/Loader';
import { filtersDataProvider } from '@/dataProvider';
import { ICompaniesFilter, ICompaniesSelect } from '@/types';

interface ICompaniesSelectProps {
  selectedCompany: string;
  setSelectedCompany: Dispatch<SetStateAction<string>>;
}

function CompaniesSelect({
  selectedCompany,
  setSelectedCompany,
}: ICompaniesSelectProps): JSX.Element {
  const t = useTranslate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [companiesFilter, setCompaniesFilter] = useState<ICompaniesSelect[]>([]);

  const handleCompanyChange = (event: SelectChangeEvent): void => {
    setSelectedCompany(event.target.value as string);
  };

  const getCompaniesFilter = async (): Promise<ICompaniesFilter[]> => {
    setIsLoading(true);
    const companies = await filtersDataProvider.getCompanies();
    const selectData = companies.map(item => ({ ...item, name: item.company_name }));
    setCompaniesFilter([
      { name: t('resources.dashboard.all_companies'), id: 'all' },
      ...selectData,
    ]);
    setIsLoading(false);

    return companies;
  };

  useEffect(() => {
    void getCompaniesFilter();
  }, []);

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: '90vh', width: '73vw' }}
        >
          <Loader />
        </Box>
      ) : (
        <FormControl fullWidth>
          <InputLabel id="dashboard-company-select-label">
            {t('resources.dashboard.choose_company')}
          </InputLabel>
          <Select
            value={selectedCompany}
            labelId="dashboard-company-select-label"
            id="dashboard-company-select"
            onChange={handleCompanyChange}
          >
            {companiesFilter.map(({ name, id }) => {
              return (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
}

export default CompaniesSelect;
