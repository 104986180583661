import {
  ReferenceInput,
  // SearchInput,
} from 'react-admin';

import { CompaniesAutocompleteInput } from '@/view/companies/CompaniesList/components/CompaniesAutocompleteInput';

const companiesFilters: JSX.Element[] = [
  // <SearchInput source="company_name" alwaysOn />,
  <ReferenceInput label="resources.employees.status" source="status" reference="company_status">
    <CompaniesAutocompleteInput />
  </ReferenceInput>,
];

export default companiesFilters;
