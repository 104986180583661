import { checkUploadEmployees } from '@/dataProvider/employeesDataProvider/checkUploadEmployeesStatus';
import { getList } from '@/dataProvider/employeesDataProvider/getList';
import { terminateEmployees } from '@/dataProvider/employeesDataProvider/terminateEmployees';
import { uploadEmployees } from '@/dataProvider/employeesDataProvider/uploadEmployees';

export const employeesDataProvider = {
  getList,
  uploadEmployees,
  terminateEmployees,
  checkUploadEmployees,
};
