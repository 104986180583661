import { API_HANDLERS } from '@/api';
import { ICompanyCreateCredentialsRequestParams } from '@/types';

export const createCredentials = async (
  data: ICompanyCreateCredentialsRequestParams,
  onSuccess?: () => void,
  // eslint-disable-next-line
  onError?: (error: any) => void,
): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.COMPANY.CREATE_CREDENTIALS(data);

    onSuccess && onSuccess();
    return response && true;
  } catch (error) {
    onError && onError(error);
    return Promise.reject(new Error(`Create Company Credentials Error: ${error}`));
  }
};
