import { isAfter, isBefore, endOfToday, startOfDay } from 'date-fns';
import {
  DateInput,
  ReferenceInput,
  SearchInput,
  type Validator,
  type ValidationErrorMessage,
} from 'react-admin';

import { TransactionsCompaniesAutocompleteInput } from '@/view/transactions/TransactionsList/components/TransactionsCompaniesAutocompleteInput';
import { TransactionsStatusAutocompleteInput } from '@/view/transactions/TransactionsList/components/TransactionsStatusAutocompleteInput';
import { WithdrawalOrderStatusAutocompleteInput } from '@/view/transactions/TransactionsList/components/WithdrawalOrderStatusAutocompleteInput';

const validateDate: Validator = (value: string): ValidationErrorMessage | null => {
  const selectedDate = new Date(value);
  const minimumDate = new Date('2024-01-01');

  if (isBefore(selectedDate, startOfDay(minimumDate))) {
    return 'Please select a date after December 31, 2023.';
  }

  if (isAfter(selectedDate, endOfToday())) {
    return 'Please select a present or past date.';
  }

  return null;
};

export const adminTransactionsFilters: JSX.Element[] = [
  <SearchInput source="full_name" alwaysOn />,
  // <TextInput source="itn" label="resources.transactions.itn" />,
  <ReferenceInput
    label="resources.transactions.status"
    source="status"
    reference="transactions_status"
  >
    <TransactionsStatusAutocompleteInput />
  </ReferenceInput>,
  <DateInput
    validate={validateDate}
    label="resources.transactions.start_date"
    name="startDate"
    source="startDate"
  />,
  <DateInput
    validate={validateDate}
    label="resources.transactions.end_date"
    name="endDate"
    source="endDate"
  />,
  <ReferenceInput
    label="resources.transactions.withdrawal_order_status"
    source="withdrawalOrderStatus"
    reference="withdrawalOrderStatus"
  >
    <WithdrawalOrderStatusAutocompleteInput />
  </ReferenceInput>,
];

export const superAdminTransactionsFilters: JSX.Element[] = [
  <SearchInput source="full_name" alwaysOn />,
  // <TextInput source="itn" label="resources.transactions.itn" />,
  <ReferenceInput
    label="resources.transactions.status"
    source="status"
    reference="transactions_status"
  >
    <TransactionsStatusAutocompleteInput />
  </ReferenceInput>,
  <ReferenceInput
    label="resources.transactions.companies"
    source="companies"
    reference="transactions_companies"
  >
    <TransactionsCompaniesAutocompleteInput />
  </ReferenceInput>,
  <DateInput
    validate={validateDate}
    label="resources.transactions.start_date"
    name="startDate"
    source="startDate"
  />,
  <DateInput
    validate={validateDate}
    label="resources.transactions.end_date"
    name="endDate"
    source="endDate"
  />,
  <ReferenceInput
    label="resources.transactions.withdrawal_order_status"
    source="withdrawalOrderStatus"
    reference="withdrawalOrderStatus"
  >
    <WithdrawalOrderStatusAutocompleteInput />
  </ReferenceInput>,
];
