import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslate, useNotify } from 'react-admin';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';

import { SectionTitle, Separator } from '@/components/form';
import { authDataProvider } from '@/dataProvider/authDataProvider';
import { getChangePasswordFormValidationSchema } from '@/view/settings/ChangePassword/components/form/ChangePasswordForm/validation';

function ChangePasswordForm(): JSX.Element {
  const t = useTranslate();
  const notify = useNotify();

  const zodSchema = getChangePasswordFormValidationSchema(t);
  type TChangePasswordForm = z.infer<typeof zodSchema>;

  const [loading, setLoading] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<TChangePasswordForm>({
    resolver: zodResolver(zodSchema),
    mode: 'onChange',
  });

  const toggleOldPasswordVisibility = (): void => {
    setShowOldPassword(!showOldPassword);
  };
  const togglePasswordVisibility = (): void => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = (): void => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSuccess = (): void => {
    setLoading(false);
    reset({
      oldPassword: '',
      password: '',
      confirmPassword: '',
    });
    notify('resources.settings.change_password_form.password_changed', {
      type: 'success',
    });
  };

  const onError = (): void => {
    setLoading(false);
    notify('resources.settings.change_password_form.errors.password_not_changed', {
      type: 'error',
    });
  };

  const handleChangePassword = async (data: TChangePasswordForm): Promise<boolean> => {
    setLoading(true);
    const response = await authDataProvider.changePassword({ data, onSuccess, onError });

    return response;
  };

  const onSubmit: SubmitHandler<TChangePasswordForm> = data => {
    void handleChangePassword(data);
  };

  return (
    <Box sx={{ width: 500 }}>
      <Separator />
      <SectionTitle label={'resources.settings.change_password_form.section_name'} />
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="oldPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('resources.settings.change_password_form.old_password')}
              type={showOldPassword ? 'text' : 'password'}
              error={!!errors.oldPassword}
              helperText={errors.oldPassword?.message || ''}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleOldPasswordVisibility}
                      edge="end"
                    >
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('resources.settings.change_password_form.new_password')}
              type={showPassword ? 'text' : 'password'}
              error={!!errors.password}
              helperText={errors.password?.message || ''}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('resources.settings.change_password_form.confirm_new_password')}
              type={showConfirmPassword ? 'text' : 'password'}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message || ''}
              margin="normal"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleConfirmPasswordVisibility}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <Button type="submit" variant="contained" disabled={!isValid || loading} sx={{ mt: 2 }}>
          {t('resources.settings.change_password')}
        </Button>
      </form>
    </Box>
  );
}

export default ChangePasswordForm;
