import { useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  DialogActions,
  Stack,
} from '@mui/material';
import { Button } from 'react-admin';
import { CloseOutlined, DoneAll } from '@mui/icons-material';
import { EuSignDialog } from '@/components/EuSignDialog';
import { transactionsDataProvider } from '@/dataProvider';
import {
  IEndUser,
  ITransactionsFilterParams,
  ITransactionsRequestParams,
  ITransactionsResponseParams,
} from '@/types';
import { SIGN_PAGINATION_SIZE } from '@/constants';
import { API_HANDLERS } from '@/api';

interface IProps {
  onClose: () => void;
  listFilters?: ITransactionsFilterParams;
}

function SignWithdrawalOrdersDialog({ onClose, listFilters }: IProps): JSX.Element {
  const translate = useTranslate();

  const [euSignInstance, setEuSignInstance] = useState<IEndUser>();
  const [isSigningFinished, setIsSigningFinished] = useState<boolean>(false);
  const [bulkPaginationPage, setBulkPaginationPage] = useState<number>(1);
  const [totalDocumentsAmount, setTotalDocumentsAmount] = useState<number>(-1);
  const [signedDocumentsAmount, setSignedDocumentsAmount] = useState<number>(0);
  const [processPercentage, setProcessPercentage] = useState<number>(0);

  const onBulkReceived = useCallback(
    async ({ data, meta }: ITransactionsResponseParams) => {
      const EndUser = window.EndUser;
      let signedAmount = 0;
      let skipedAmount = 0;

      if (!euSignInstance) {
        return;
      }

      if (totalDocumentsAmount === -1) {
        setTotalDocumentsAmount(meta.itemCount);
      }

      for (const order of data) {
        if (order.withdrawal_order_link && !order.signed_order_link) {
          const response = await fetch(order.withdrawal_order_link);
          const document = await response.arrayBuffer();
          const dataArray = new Uint8Array(document);

          try {
            const signed = await euSignInstance.SignData(
              dataArray,
              false,
              false,
              EndUser.SignAlgo.DSTU4145WithGOST34311,
              null,
              EndUser.SignType.CAdES_X_Long_Trusted,
            );

            const signedFile = new Blob([signed], {
              type: 'application/pkcs7-signature',
            });

            await transactionsDataProvider.signTransactionWithdrawalOrder({
              transactionId: order.id,
              file: signedFile,
            });

            signedAmount++;

            setSignedDocumentsAmount(signedDocumentsAmount + signedAmount);
          } catch (error) {
            console.log(error);
          }
        } else {
          skipedAmount++;

          setTotalDocumentsAmount(
            (totalDocumentsAmount === -1 ? meta.itemCount : totalDocumentsAmount) - skipedAmount,
          );
        }
      }

      if (meta.hasNextPage) {
        setBulkPaginationPage(meta.page + 1);
      } else {
        setIsSigningFinished(true);
      }
    },
    [signedDocumentsAmount, totalDocumentsAmount, euSignInstance],
  );

  useEffect(() => {
    if (!euSignInstance) {
      return;
    }

    const requestParams: ITransactionsRequestParams = {
      filter: listFilters,
      order: 'ASC',
      page: bulkPaginationPage,
      take: SIGN_PAGINATION_SIZE,
    };

    if (listFilters?.full_name) {
      requestParams.search = listFilters.full_name;
    }

    API_HANDLERS.TRANSACTIONS.GET_LIST(requestParams).then(({ data }) => onBulkReceived(data));
  }, [euSignInstance, bulkPaginationPage]);

  const onKeyReceived = useCallback((instance: Window['EndUser']) => {
    setEuSignInstance(instance);
  }, []);

  useEffect(() => {
    if (totalDocumentsAmount > 0) {
      setProcessPercentage(Math.round((signedDocumentsAmount / totalDocumentsAmount) * 100));
    }
  }, [signedDocumentsAmount, totalDocumentsAmount]);

  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle id="alert-dialog-title">
        {translate('resources.transactions.withdrawal_order_dialog.title')}
      </DialogTitle>
      <DialogContent>
        {<EuSignDialog onKeyReceived={onKeyReceived} />}
        {euSignInstance && !isSigningFinished && (
          <>
            <Box>
              <Typography sx={{ pt: 2 }} variant="body1" align="center" color="primary">
                {translate('resources.transactions.withdrawal_order_dialog.title_inner')}
              </Typography>
              <Typography sx={{ pb: 2 }} variant="body1" align="center" color="error">
                {translate('resources.transactions.withdrawal_order_dialog.warning_inner')}
              </Typography>
            </Box>
            <LinearProgress variant="determinate" color="secondary" value={processPercentage} />
            <Box>
              <Typography sx={{ p: 2 }} variant="body1" align="center">
                {processPercentage}%
              </Typography>
            </Box>
          </>
        )}
        {isSigningFinished && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <DoneAll color="success" />
            <Typography sx={{ p: 2 }} variant="body1">
              {translate('resources.transactions.withdrawal_order_dialog.all_signed')}
            </Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        {!isSigningFinished && (
          <Button startIcon={<CloseOutlined />} label="pos.cancel" onClick={onClose} />
        )}
        {isSigningFinished && (
          <Button startIcon={<CloseOutlined />} label="pos.close" onClick={onClose} />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SignWithdrawalOrdersDialog;
