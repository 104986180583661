import { API_PATHS } from '@/api';
import { getData } from '@/api/crudHandlers';
import {
  TAxiosResponseSuccess,
  TFiltersRequestParams,
  IFiltersResponseParams,
  EmployeesStatusEnum,
  ICompaniesFilterResponseParams,
} from '@/types';

export const filtersHandlers = {
  GET_FILTERS(
    type: TFiltersRequestParams,
  ): TAxiosResponseSuccess<IFiltersResponseParams<EmployeesStatusEnum>> {
    return getData<IFiltersResponseParams<EmployeesStatusEnum>>(
      API_PATHS.FILTERS.GET_FILTERS.BY_ID(type),
    );
  },
  GET_COMPANY(): TAxiosResponseSuccess<ICompaniesFilterResponseParams> {
    return getData<ICompaniesFilterResponseParams>(API_PATHS.FILTERS.GET_COMPANIES._);
  },
};
