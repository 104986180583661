import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
  Button,
  useUpdateMany,
  useNotify,
  useUnselectAll,
  Identifier,
  useListContext,
} from 'react-admin';

const noSelection: Identifier[] = [];

function BulkActivateButton(): JSX.Element {
  const { selectedIds = noSelection } = useListContext();
  const notify = useNotify();
  const unselectAll = useUnselectAll('employees');

  const [updateMany, { isLoading }] = useUpdateMany(
    'employees',
    { ids: selectedIds, data: { status: 'Очікується відновлення', is_terminated: false } },
    {
      mutationMode: 'undoable',
      onSuccess: () => {
        notify('resources.reviews.notification.approved_success', {
          type: 'info',
          undoable: true,
        });
        unselectAll();
      },
      onError: () => {
        notify('resources.reviews.notification.approved_error', {
          type: 'error',
        });
      },
    },
  );

  return (
    <Button label="resources.employees.activate" onClick={() => updateMany()} disabled={isLoading}>
      <PlayCircleIcon />
    </Button>
  );
}

export default BulkActivateButton;
