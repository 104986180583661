import { API_HANDLERS } from '@/api';
import { IChangeForgotPasswordRequestParams } from '@/types';

interface IChangeForgotPasswordRequestData {
  data: IChangeForgotPasswordRequestParams;
  onSuccess: () => void;
  // eslint-disable-next-line
  onError: (error: any) => void;
}

export const changeForgotPassword = async ({
  data,
  onError,
  onSuccess,
}: IChangeForgotPasswordRequestData): Promise<boolean> => {
  try {
    const response = await API_HANDLERS.AUTH.CHANGE_FORGOT_PASSWORD(data);

    onSuccess();
    return response && true;
  } catch (error) {
    onError(error);
    return Promise.reject(new Error(`Change Forgot Password Error: ${error}`));
  }
};
