export function getLastDayOfMonth(inputDateStr: string): Date {
  const inputDate = new Date(inputDateStr);
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const inputYear = inputDate.getFullYear();
  const inputMonth = inputDate.getMonth();

  if (inputYear === currentYear && inputMonth === currentMonth) {
    return today;
  } else {
    const lastDayOfMonth = new Date(inputYear, inputMonth + 1, 0);
    return lastDayOfMonth;
  }
}
