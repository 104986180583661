import { TPermissionsType, IRbac } from '@/types';

export function transformPermissionsData(permissions: TPermissionsType[]): IRbac[] {
  return permissions.flatMap(item =>
    Object.entries(item).map(
      ([key, actions]): IRbac => ({
        action: actions,
        resource: key.toLowerCase(),
      }),
    ),
  );
}
