export interface IYearOption {
  name: string;
  id: string;
}

export function generateYearOptions(): IYearOption[] {
  const currentYear = new Date().getFullYear();
  const startYear = 2024;
  const years: IYearOption[] = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({ name: `${year}`, id: `${year}` });
  }

  return years;
}
